import { Routes } from '@angular/router';
import { FrogisComponent } from "./frogis/frogis.component";
var routes = [
    { path: 'frogis', component: FrogisComponent },
    { path: '**', redirectTo: 'frogis' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
