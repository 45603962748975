/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./progress-dialog.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "./progress-dialog.component";
import * as i10 from "@angular/material/dialog";
var styles_ProgressDialogComponent = [i0.styles];
var RenderType_ProgressDialogComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ProgressDialogComponent, data: {} });
export { RenderType_ProgressDialogComponent as RenderType_ProgressDialogComponent };
export function View_ProgressDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["mat-dialog-title", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["mat-dialog-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["mat-dialog-actions", ""], ["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["role", "progressbar"]], [[4, "display", null], [1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(7, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["color", "primary"], ["mat-button", ""], ["mat-stroked-button", ""], ["target", "_blank"]], [[8, "href", 4], [4, "display", null], [1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatAnchor_0, i5.RenderType_MatAnchor)), i1.ɵdid(9, 180224, null, 0, i6.MatAnchor, [i7.Platform, i8.FocusMonitor, i1.ElementRef, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(10, 0, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["mat-button", ""], ["mat-stroked-button", ""]], [[4, "display", null], [1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MatAnchor_0, i5.RenderType_MatAnchor)), i1.ɵdid(12, 180224, null, 0, i6.MatAnchor, [i7.Platform, i8.FocusMonitor, i1.ElementRef, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.progressValue; var currVal_7 = _co.progressType; _ck(_v, 7, 0, currVal_6, currVal_7); var currVal_14 = "primary"; _ck(_v, 9, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.content; _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.showProgress ? "none" : "block"); var currVal_3 = i1.ɵnov(_v, 7).value; var currVal_4 = i1.ɵnov(_v, 7).mode; var currVal_5 = i1.ɵnov(_v, 7)._isNoopAnimation; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = i1.ɵinlineInterpolate(1, "", _co.data.url, ""); var currVal_9 = ((_co.showProgress || _co.data.hideOk) ? "none" : "block"); var currVal_10 = (i1.ɵnov(_v, 9).disabled ? (0 - 1) : (i1.ɵnov(_v, 9).tabIndex || 0)); var currVal_11 = (i1.ɵnov(_v, 9).disabled || null); var currVal_12 = i1.ɵnov(_v, 9).disabled.toString(); var currVal_13 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_15 = _co.data.buttonName; _ck(_v, 10, 0, currVal_15); var currVal_16 = (_co.showProgress ? "none" : "block"); var currVal_17 = (i1.ɵnov(_v, 12).disabled ? (0 - 1) : (i1.ɵnov(_v, 12).tabIndex || 0)); var currVal_18 = (i1.ɵnov(_v, 12).disabled || null); var currVal_19 = i1.ɵnov(_v, 12).disabled.toString(); var currVal_20 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); }); }
export function View_ProgressDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "progress-dialog", [], null, null, null, View_ProgressDialogComponent_0, RenderType_ProgressDialogComponent)), i1.ɵdid(1, 114688, null, 0, i9.ProgressDialogComponent, [i10.MatDialogRef, i10.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgressDialogComponentNgFactory = i1.ɵccf("progress-dialog", i9.ProgressDialogComponent, View_ProgressDialogComponent_Host_0, {}, {}, []);
export { ProgressDialogComponentNgFactory as ProgressDialogComponentNgFactory };
