import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrogisComponent } from "./frogis/frogis.component";

const routes: Routes = [
    { path: 'frogis', component: FrogisComponent },
    { path: '**', redirectTo: 'frogis' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
