import { OnInit } from "@angular/core";
import { DivisionMethod } from "./frogis.model";
import { FormBuilder, Validators } from "@angular/forms";
import { ConfigurationService } from "./configuration.service";
import { CalculationService } from "./calculation.service";
import { MatTableDataSource } from "@angular/material/table";
import { LatLng, tileLayer, geoJSON, Control, DomUtil, Browser } from "leaflet";
import { FrogisService } from "./frogis.service";
import { DebugComponent } from "./debug.component";
export var monotonicValidator = function (formGroup) {
    var rangeArray = formGroup.get('ranges');
    if (rangeArray.controls.length < 1) {
        return null;
    }
    var lastVal;
    var i = 0;
    var min = rangeArray.controls[0].get('range').value;
    var max = rangeArray.controls[rangeArray.controls.length - 1].get('range').value;
    for (var _i = 0, _a = rangeArray.controls; _i < _a.length; _i++) {
        var control = _a[_i];
        var curVal = control.get('range').value;
        if (curVal.value == "") {
            var o = { empty: true };
            control.get('range').setErrors(o);
            return o;
        }
        if (!lastVal) {
            lastVal = curVal;
        }
        if (i == 0 || i == rangeArray.controls.length - 1) {
            i++;
            continue;
        }
        if (curVal < min || curVal > max) {
            var o = { outOfRange: true };
            control.get('range').setErrors(o);
            return o;
        }
        if (lastVal >= curVal) {
            var o = { notMonotonic: true };
            control.get('range').setErrors(o);
            return o;
        }
        lastVal = curVal;
        i++;
    }
    return null;
};
var GoalSummaryComponent = /** @class */ (function () {
    function GoalSummaryComponent(calculationService, configurationService, frogisService, debugComponent) {
        var _this = this;
        this.calculationService = calculationService;
        this.configurationService = configurationService;
        this.frogisService = frogisService;
        this.debugComponent = debugComponent;
        this.formBuilder = new FormBuilder();
        this.goalClassRanges = [];
        this.classDivisionMethods = DivisionMethod;
        this.goalValues = [];
        this.goalClasses = [];
        this.colorMap = ['', "#ffffd4", "#fed98e", "#fe9929", "#d95f0e", "#993404"];
        this.leafletZoom = 11;
        this.leafletCenter = new LatLng(52.23, 21.03);
        this.showMap = false;
        this.defaultLineColor = "#666666";
        this.goalClassDivisionForm = this.formBuilder.group({
            numClasses: ['', Validators.required],
            divisionMethod: ['', Validators.required],
        });
        this.configurationService.getGoalClassDivision().subscribe({
            next: function (goalClassDivision) {
                if (!goalClassDivision) {
                    return;
                }
                _this.goalClassDivisionForm.setValue(goalClassDivision);
                _this.onChangeClassDivision(goalClassDivision.numClasses);
                _this.refreshStatsValues();
            }
        });
        this.configurationService.getGoalClassBreaks().subscribe({
            next: function (goalClassBreaks) {
                if (!goalClassBreaks) {
                    return;
                }
                _this.buildGoalSummaryForm(goalClassBreaks);
            }
        });
        this.configurationService.getGoalValues().subscribe({
            next: function (goalValues) {
                _this.goalValues = goalValues;
                _this.refreshStatsValues();
            }
        });
        this.configurationService.getGoalClasses().subscribe({
            next: function (goalClasses) {
                _this.goalClasses = goalClasses;
                console.log('refresh goal classes');
                _this.refreshMap();
            }
        });
    }
    GoalSummaryComponent.prototype.ngOnInit = function () {
        this.goalSummaryForm = this.formBuilder.group({
            ranges: this.formBuilder.array([]),
        });
    };
    GoalSummaryComponent.prototype.onMapReady = function (map) {
        this.map = map;
        if (!this.showMap || !this.goalClasses) {
            return;
        }
    };
    GoalSummaryComponent.prototype.onLayerMouseOver = function (component) {
        return function (e) {
            var target = e.target;
            var properties = target.feature.properties;
            var data = component.debugComponent.getData();
            var featureData = data[properties.ID * 1 - 1];
            var text = '';
            for (var key in featureData) {
                text += "<b>" + key + "</b>: " + featureData[key] + "<br>";
            }
            target.bindPopup(text);
            target.openPopup();
            target.setStyle({
                weight: 2,
            });
            console.log(data[properties.ID]);
            target._map.owner.mapInfoBox.update(properties);
            if (!Browser.ie && !Browser.opera12 && !Browser.edge) {
                target.bringToFront();
            }
        };
    };
    GoalSummaryComponent.prototype.onLayerMouseOut = function (e) {
        e.target.setStyle({
            weight: 1,
        });
        e.target._map.owner.mapInfoBox.update(null);
        e.target.closePopup();
    };
    GoalSummaryComponent.prototype.refreshMap = function (force) {
        var _this = this;
        if (force === void 0) { force = false; }
        if (!this.goalSummaryForm.valid) {
            return;
        }
        if (force) {
            this.showMap = true;
        }
        if (!this.showMap || !this.goalClasses) {
            return;
        }
        setTimeout(function () {
            _this.map.invalidateSize();
        }, 100);
        var tiles = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });
        tiles.addTo(this.map);
        var map = this.map;
        map.owner = this;
        this.addLegendToMap(map);
        if (!this.spuJSON) {
            this.frogisService.getGeoJson().then(function (json) {
                _this.spuJSON = json;
                _this.updateMap(_this.spuJSON);
            }).catch(
            //@2do show error
            );
        }
        else {
            this.updateMap(this.spuJSON);
        }
    };
    GoalSummaryComponent.prototype.updateMap = function (json) {
        var component = this;
        for (var i in json.features) {
            var goalClass = component.goalClasses[i];
            json.features[i].properties['class'] = (goalClass) ? goalClass : 'n/a';
        }
        if (this.spuMap) {
            this.map.removeLayer(this.spuMap);
        }
        this.spuMap = geoJSON(json, {
            onEachFeature: function (feature, layer) {
                layer.bindTooltip(feature.properties['ID'] + '', { permanent: true, direction: 'center' });
                layer.on({
                    mouseover: component.onLayerMouseOver(component),
                    mouseout: component.onLayerMouseOut,
                });
            },
            style: function (feature) {
                if (feature.properties['class'] != 'n/a') {
                    return {
                        color: component.defaultLineColor,
                        fillColor: component.colorMap[feature.properties['class']],
                        weight: 1,
                        opacity: 1,
                        dashArray: '3',
                        fillOpacity: 0.7,
                    };
                }
                return {
                    color: component.defaultLineColor,
                    fillColor: "#ffffff",
                    weight: 1,
                    opacity: 1,
                    dashArray: '3',
                    fillOpacity: 0.0,
                };
            },
        });
        this.spuMap.addTo(this.map);
        this.leafletCenter = this.spuMap.getBounds().getCenter();
    };
    GoalSummaryComponent.prototype.addLegendToMap = function (map) {
        if (this.mapInfoBox) {
            return;
        }
        var component = this;
        this.mapInfoBox = new Control();
        this.mapInfoBox.onAdd = function () {
            this._div = DomUtil.create('div', 'map-box');
            this.update();
            return this._div;
        };
        this.mapInfoBox.update = function (properties) {
            if (properties === void 0) { properties = null; }
            this._div.innerHTML = "<h5>Valorization goal</h5>" + (properties ?
                "<b>ID:</b> " + properties['ID'] + "<br><b>class: </b>" + properties.class : 'Point area on map.');
        };
        this.mapInfoBox.addTo(map);
        this.mapLegendBox = new Control({ position: 'bottomright' });
        this.mapLegendBox.onAdd = function () {
            this._div = DomUtil.create('div', 'map-box map-legend');
            this.update(component.goalClassRanges.length);
            return this._div;
        };
        this.mapLegendBox.update = function (numClasses) {
            this._div.innerHTML = '<h5>Neads and posibility for water retention</h5>';
            for (var i = 1; i < numClasses; i++) {
                var label = i + "";
                if (i == 1) {
                    label += " low potential";
                }
                else if (i == numClasses - 1) {
                    label += " high potential";
                }
                this._div.innerHTML += "<i style=\"background:" + component.colorMap[i] + "\"></i>" + label + "<br>";
            }
            return this._div;
        };
        this.mapLegendBox.addTo(map);
    };
    GoalSummaryComponent.prototype.calculateGoalClassRanges = function () {
        if (!this.goalClassDivisionForm.valid) {
            return;
        }
        var classDivision = this.goalClassDivisionForm.getRawValue();
        if (this.goalValues) {
            console.log('has goal values');
            var breaks = this.calculationService.calculateBreaks(classDivision.numClasses, classDivision.divisionMethod, this.goalValues);
            this.onChangeClassDivision(classDivision.numClasses);
            this.buildGoalSummaryForm(breaks);
            this.refreshStatsValues();
            this.configurationService.setGoalClassDivision(this.goalClassDivisionForm.getRawValue());
            this.onChangeClassDivision(classDivision.numClasses);
            this.recalculateAndSaveClassDivision(this.goalSummaryForm.getRawValue());
        }
        console.log(classDivision);
    };
    GoalSummaryComponent.prototype.buildGoalSummaryForm = function (breaks) {
        var _this = this;
        var inputControls = [];
        for (var i = 0; i < breaks.length; i++) {
            var breakValue = breaks[i];
            if (!breakValue) {
                breakValue = 0;
            }
            inputControls.push(this.formBuilder.group({
                range: [parseFloat(breakValue.toFixed(2)), Validators.required],
            }));
        }
        this.goalSummaryForm = this.formBuilder.group({
            ranges: this.formBuilder.array(inputControls),
        }, { validator: monotonicValidator });
        this.goalSummaryForm.markAllAsTouched();
        // when any value in this form has changed
        this.goalSummaryForm.valueChanges.subscribe(function (values) {
            if (_this.goalSummaryForm.valid) {
                _this.recalculateAndSaveClassDivision(values);
            }
        });
    };
    GoalSummaryComponent.prototype.onChangeClassDivision = function (numClasses) {
        var ranges = [];
        for (var i = 0; i < numClasses + 1; i++) {
            ranges.push(i);
        }
        if (this.mapLegendBox) {
            this.mapLegendBox.update(numClasses + 1);
        }
        this.goalClassRanges = ranges;
    };
    GoalSummaryComponent.prototype.recalculateAndSaveClassDivision = function (formValues) {
        var breaks = [];
        for (var _i = 0, _a = formValues.ranges; _i < _a.length; _i++) {
            var range = _a[_i];
            breaks.push(range.range);
        }
        console.log(breaks);
        console.log(this.goalValues);
        var classes = this.calculationService.changeValuesToClasses(this.goalValues, breaks, true);
        console.log(classes);
        this.configurationService.setGoalClasses(classes);
        this.configurationService.setGoalClassBreaks(breaks);
        this.showMap = true;
    };
    GoalSummaryComponent.prototype.refreshStatsValues = function () {
        if (this.goalClassDivisionForm.valid && this.goalValues && this.goalValues.length > 0) {
            var stats = this.calculationService.calculateBasicStats(this.goalValues);
            var goalSummary = [{
                    mean: stats.mean,
                    min: stats.min,
                    max: stats.max,
                    stDev: stats.stDev,
                }];
            this.goalSummaryDataSource = new MatTableDataSource(goalSummary);
        }
    };
    GoalSummaryComponent.prototype.downloadCsv = function () {
        var data = this.debugComponent.getData();
        if (data.length == 0) {
            alert("Cannot generate geojson file because there are no features.");
            return;
        }
        var header = Object.keys(data[0]);
        var csv = data.map(function (row) {
            return header.map(function (name) { return row[name]; }).join(",");
        });
        csv.unshift(header.join(","));
        this.downloadFile(csv.join("\r\n"), "report.csv", 'text/csv');
    };
    GoalSummaryComponent.prototype.downloadGeoJSON = function () {
        var geoJSON = Object.assign({}, this.spuMap.toGeoJSON());
        var data = this.debugComponent.getData();
        for (var i in geoJSON.features) {
            geoJSON.features[i].properties = data[i];
        }
        this.downloadFile(JSON.stringify(geoJSON), "report.geojson", 'text/plain;charset=utf-8');
    };
    GoalSummaryComponent.prototype.downloadFile = function (o, fileName, fileType) {
        var a = document.createElement('a');
        var url = window.URL.createObjectURL(new Blob([o], { type: fileType }));
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };
    return GoalSummaryComponent;
}());
export { GoalSummaryComponent };
