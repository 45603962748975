import { OnInit } from "@angular/core";
import { MatDialogRef } from '@angular/material';
var ProgressDialogData = /** @class */ (function () {
    function ProgressDialogData() {
        this.title = "";
        this.content = "";
        this.successContent = "";
        this.buttonName = "";
        this.url = "";
        this.hideOk = false;
    }
    return ProgressDialogData;
}());
export { ProgressDialogData };
var ProgressDialogComponent = /** @class */ (function () {
    function ProgressDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.showProgress = true;
        this.progressType = "indeterminate";
        this.progressValue = 0;
    }
    ProgressDialogComponent.prototype.ngOnInit = function () {
        if (this.data.progress != null) {
            this.progressType = "determinate";
            var component_1 = this;
            this.data.progress.subscribe({
                next: function (value) {
                    component_1.progressValue = value;
                },
                complete: function () {
                    if (component_1.data.successContent != "") {
                        component_1.data.content = component_1.data.successContent;
                    }
                    component_1.hideProgress();
                },
                error: function (data) {
                    component_1.data.title = "Error";
                    component_1.data.content = data.error.message;
                    component_1.hideProgress();
                }
            });
        }
    };
    ProgressDialogComponent.prototype.closeClick = function () {
        this.dialogRef.close();
    };
    ProgressDialogComponent.prototype.hideProgress = function () {
        this.showProgress = false;
    };
    return ProgressDialogComponent;
}());
export { ProgressDialogComponent };
