import { MatDialogRef } from '@angular/material';
var YesNoDialogComponent = /** @class */ (function () {
    function YesNoDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    YesNoDialogComponent.prototype.yesClick = function () {
        this.dialogRef.close(true);
    };
    YesNoDialogComponent.prototype.noClick = function () {
        this.dialogRef.close(false);
    };
    return YesNoDialogComponent;
}());
export { YesNoDialogComponent };
