import {Component, ViewEncapsulation} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {ConfigurationService} from "./configuration.service";
import {FrogisService} from "./frogis.service";

@Component({
    selector: "debug",
    templateUrl: './debug.component.html',
    styleUrls: ['./debug.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DebugComponent {
    indicatorsColumns = [];
    indicatorsDataSource: MatTableDataSource<any>;
    indicatorsDisplayedColumns;

    calculationResults: any = {};
    indicatorClasses: any = {};
    goalValues: number[] = [];
    goalClasses: number[] = [];
    data: any;

    constructor(
        private configurationService: ConfigurationService,
    ) {
        this.configurationService.getCalculationResult().subscribe(
            {
                next: value => {
                    this.calculationResults = value;

                    this.showIndicatorsResult();
                }
            }
        );

        this.configurationService.getIndicatorClasses().subscribe(
            {
                next: value => {
                    this.indicatorClasses = value;

                    this.showIndicatorsResult();
                }
            }
        );

        this.configurationService.getGoalValues().subscribe(
            {
                next: goalValues => {
                    this.goalValues = goalValues;

                    this.showIndicatorsResult();
                }
            }
        );

        this.configurationService.getGoalClasses().subscribe(
            {
                next: goalClasses => {
                    this.goalClasses = goalClasses;

                    this.showIndicatorsResult();
                }
            }
        );
    }

    private showIndicatorsResult() {
        let data = [];
        let columns = [];
        let indicators = Object.keys(this.calculationResults);

        let spuNums = 0;

        if (indicators.length > 0) {
            let indicatorId = indicators[0];
            spuNums = this.calculationResults[indicatorId].length;

            for (let i=0; i<spuNums; i++) {
                data.push({spu: i + 1});
            }
        }

        for (let indicatorId of indicators) {
            let isIndicatorClasses = this.indicatorClasses[indicatorId];

            for (let i=0; i<spuNums; i++) {
                data[i][indicatorId] = this.calculationResults[indicatorId][i];

                if (isIndicatorClasses) {
                    data[i][indicatorId + "Classes"] = this.indicatorClasses[indicatorId][i];
                }
            }

            columns.push(indicatorId);
            if (isIndicatorClasses) {
                columns.push(indicatorId + "Classes");
            }
        }

        let indicatorsColumns = [
            { columnDef: "spu",
                header: "SPU",
                classIndicator: (columnIndicatorId: string) => `mat-cell`,
                cell: (columnIndicatorId: string, row: any) => `${row["spu"]}`,
            }
        ];

        if (data.length > 0) {
            if (this.goalValues && this.goalValues.length > 0) {
                columns.push("goalValue");

                for (let i = 0; i < this.goalValues.length; i++) {
                    data[i]["goalValue"] = this.goalValues[i];
                }
            }

            if (!this.goalClasses) {
                this.goalClasses = [];
            }

            if (this.goalClasses.length > 0) {
                columns.push("goalClass");

                for (let i = 0; i < this.goalClasses.length; i++) {
                    data[i]["goalClass"] = this.goalClasses[i];
                }
            }

            for (let columnId of columns) {
                indicatorsColumns.push(
                    {
                        columnDef: columnId,
                        header: columnId,
                        classIndicator: (columnIndicatorId: string) => `mat-cell show-indicator`,
                        cell: (columnIndicatorId: string, row: any) => `${row[columnIndicatorId]}`,
                    }
                );
            }
        }

        this.indicatorsColumns = indicatorsColumns;
        this.indicatorsDisplayedColumns = this.indicatorsColumns.map(c => c.columnDef);
        this.data = data;
        this.indicatorsDataSource = new MatTableDataSource<any>(data);
    }

    reset(): void {
        this.configurationService.reset();
    }

    public getData(): any {
        return this.data;
    }
}
