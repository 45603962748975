<nav>
    <mat-toolbar class="mat-elevation-z6">
        <img class="logo" style="margin-right:1px" src="/assets/img/framwat-logo.gif">
        <a href="https://www.gov.pl/web/edukacja-i-nauka/" target="_blank"><img class="logo" style="border-width:12px;background:#ffffff" src="/assets/img/logo-mnisw.png"></a>
        <h1 style="color:#ffffff;margin-left:20px">FROGIS</h1>
        <ul>
            <li><a href="https://e.sggw.pl/course/view.php?id=1709" target="_blank">E-leearning</a></li>
            <li><a href="https://www.interreg-central.eu/Content.Node/FramWat.html" target="_blank">Framwat</a></li>
            <li><a href="https://drive.google.com/open?id=1iRxxHOCNuDdSB1tqBhnXtNLf14mr0S2Vl60tr8AGUc4" target="_blank">Methodology</a></li>
            <li><a href="https://drive.google.com/open?id=160XyXj2tB3OP3fNdvU7aKN3xBRWOb0VAGepk-qKOz1U" target="_blank">Manual</a></li>
            <li><a href="https://drive.google.com/open?id=1U7ldyZMqgrJrnHQN4NW1ODzUwjBUz3J-" target="_blank">Exemplary data</a></li>
            <li><a href="https://drive.google.com/open?id=1Ci4d_HMaJFh8Ko7WupFsWo-PJS8j2B18Nw6SeujIBxc" target="_blank">Changelog</a></li>
        </ul>
        <span class="version">{{version}}</span>
    </mat-toolbar>
</nav>

<router-outlet></router-outlet>
