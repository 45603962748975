import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {FrogisService} from "./frogis.service";
import {GoalType, Indicator, Indicators, Input, Inputs, RecommendedIndicators, SpuType} from "./frogis.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatDialog} from "@angular/material/dialog";
import {ProgressDialogComponent} from "../shared/progress-dialog.component";
import {ConfigurationService} from "./configuration.service";
import {Subject} from "rxjs";
import {GoalSummaryComponent} from "./goal-summary.component";
import {CorrelationMatrixComponent} from "./correlation-matrix.component";
import {IndicatorsSummaryComponent} from "./indicators-summary.component";

@Component({
    templateUrl: './frogis.component.html',
    styleUrls: ['./frogis.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('void', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class FrogisComponent implements OnInit, OnDestroy {

    @ViewChild('shapeFile', { static: false }) shapeFile;
    @ViewChild('rasterFile', { static: false }) rasterFile;
    @ViewChild('indicatorsSummary', {static: false}) indicatorsSummary: IndicatorsSummaryComponent;
    @ViewChild('correlationMatrix', {static: false}) correlationMatrix: CorrelationMatrixComponent;
    @ViewChild('goalSummary', {static: false}) goalSummary: GoalSummaryComponent;

    step: number = 0;

    showDebug = false;

    goalTypes = GoalType;
    spuTypes = SpuType;
    recommendedIndicatorsForGoal = RecommendedIndicators;

    spatialPlanningUnitsForm: FormGroup;
    goalAndIndicatorsForm: FormGroup;

    formBuilder:FormBuilder = new FormBuilder();

    indicatorsDataSource: MatTableDataSource<Indicator>;
    inputsDataSource: MatTableDataSource<Input>;
    inputs = Inputs;
    globalDataCoverage: any = {};

    fileToUpload: File;
    filesUploadProgress;

    showInputsPanel: boolean = false;
    selectedGoal: GoalType;
    selectedInputIdToUpload: string = "";
    preSelectedIndicators: string[] = [];
    fileUploadStatus: any = {};
    showIndicator: any = {};

    expandedIndicator: Indicator | null;

    progressDialog;

    haveAllInputDataToProcess: boolean = false;
    haveGoalValues: boolean = false;
    haveSelectedIndicators: boolean = false;
    hasSpuFile: boolean = false;

    constructor(
        private frogisService: FrogisService,
        private configurationService: ConfigurationService,
        private dialog: MatDialog,
    ){
        this.spatialPlanningUnitsForm = this.formBuilder.group(
            {
                spuType: ['', Validators.required],
            }
        );

        this.spatialPlanningUnitsForm.valueChanges.subscribe({
            next: values => {
                this.configurationService.setSpuType(values.spuType)
            }
        });

        this.goalAndIndicatorsForm = this.formBuilder.group(
            {
                goal: ['', Validators.required],
            }
        );

        this.goalAndIndicatorsForm.valueChanges.subscribe({
            next: values => {
                console.log(values);
                this.selectedGoal = this.goalAndIndicatorsForm.get('goal').value;
                this.configurationService.setGoal(values.goal);
                this.refreshIndicators(values.goal);
            }
        });

        this.indicatorsDataSource = new MatTableDataSource<Indicator>(Indicators as Indicator[]);

        this.configurationService.getSpuType().subscribe(
            {
                next: spuType => {
                    if (!spuType) {
                        return;
                    }

                    this.spatialPlanningUnitsForm.setValue({spuType: spuType});
                }
            }
        );

        this.configurationService.getGlobalDataCoverage().subscribe({
            next: values => {
                this.globalDataCoverage = values;
            }
        });

        this.configurationService.getGoal().subscribe(
            {
                next: goal => {
                    if (!goal) {
                        return;
                    }

                    this.selectedGoal = goal;

                    this.goalAndIndicatorsForm.setValue({goal: goal});

                    this.refreshIndicators(goal);
                }
            }
        );

        this.configurationService.getPreselectedIndicators().subscribe(
            {
                next: value => { this.preSelectedIndicators = value; }
            }
        );

        this.configurationService.getFileUploadStatus().subscribe(
            {
                next: value => {
                    this.fileUploadStatus = value;

                    if (value["SubBasin"] && value.SubBasin.status == "success") {
                        this.hasSpuFile = true;
                    }
                }
            }
        );

        this.configurationService.getHasAllRequiredInputData().subscribe(
            {
                next: value => { this.haveAllInputDataToProcess = value; }
            }
        );

        this.configurationService.getSelectedInputs().subscribe(
            {
                next: inputs => {
                    this.showInputsPanel = inputs.length > 0;
                    this.inputsDataSource = new MatTableDataSource<Input>(inputs);
                }
            }
        );

        this.configurationService.getSelectedIndicators().subscribe(
            {
                next: selectedIndicators => {
                    this.haveSelectedIndicators = selectedIndicators.length > 0;
                }
            }
        );

        this.configurationService.getGoalValues().subscribe(
            {
                next: goalValues => {
                    this.haveGoalValues = goalValues.length > 0;
                }
            }
        );
    }

    ngOnInit() {

    }

    ngOnDestroy() {
    }

    setStep(step: number): void {
        this.step = step;
    }

    isIndicatorRecommendedForCurrentGoal(indicatorId: string): boolean {
        if (!this.selectedGoal || !this.recommendedIndicatorsForGoal || !this.recommendedIndicatorsForGoal[this.selectedGoal]) {
            return false;
        }

        return this.recommendedIndicatorsForGoal[this.selectedGoal].includes(indicatorId);
    }

    changePreselectedIndicator(indicatorId: string, event: any) {
        if (event.checked) {
            this.configurationService.addPreselectedIndicator(indicatorId);
        } else {
            this.configurationService.removePreselectedIndicator(indicatorId);
        }
    }

    refreshIndicators(goal: GoalType): void {
        for (const i in (Indicators as Indicator[])) {
            const indicator = Indicators[i];

            this.showIndicator[indicator.id] = this.configurationService.getIndicatorById(indicator.id).goal.includes(GoalType[goal]);
        }

        console.log(this.showIndicator);
    }

    onFilesAdded(type: string) {
        const files: { [key: string]: File } = (type == 'shapefile') ? this.shapeFile.nativeElement.files : this.rasterFile.nativeElement.files;

        for (let key in files) {
            if (!isNaN(parseInt(key))) {
                this.fileToUpload = files[key];
                break;
            }
        }

        this.filesUploadProgress = this.frogisService.upload(this.fileToUpload, this.selectedInputIdToUpload);

        this.progressDialog = this.dialog.open(ProgressDialogComponent, {
            data: {
                title: `File upload`,
                content: `Uploading file: ${this.fileToUpload.name}`,
                successContent: `File successfully uploaded: ${this.fileToUpload.name}`,
                buttonName: `Ok`,
                progress: this.filesUploadProgress,
                hideOk: true
            },
            height: '200px',
            width: '320px',
        }).componentInstance;

        const component = this;

        this.filesUploadProgress.subscribe({
            complete: status => {
                component.configurationService.setFileUploadStatus(component.selectedInputIdToUpload, {
                    status: "success",
                });

                if (component.selectedInputIdToUpload == "SubBasin") {
                    this.hasSpuFile = true;
                }
            },
            error: error => {
                if (component.selectedInputIdToUpload == "SubBasin") {
                    this.hasSpuFile = false;
                }
            }
        });
    }

    addFiles(inputId: string) {
        this.selectedInputIdToUpload = inputId;

        if (this.configurationService.getInputById(inputId).file == "shapefile") {
            this.shapeFile.nativeElement.click();
        } else {
            this.rasterFile.nativeElement.click();
        }
    }

    downloadLayerFromGlobalData(inputIds: string[]) {
        this.frogisService.download(inputIds)

        const progress = new Subject<number>();

        this.progressDialog = this.dialog.open(ProgressDialogComponent, {
            data: {
                title: `Global data`,
                content: `Receiving global data`,
                successContent: `Received all data`,
                buttonName: `Ok`,
                progress: progress.asObservable(),
                hideOk: true
            },
            height: '250px',
            width: '520px',
        }).componentInstance;

        setTimeout(() => { this.checkDownloadStatus(progress); }, 5000);
    }

    private checkDownloadStatus(progress) {
        const component = this;

        this.frogisService.getDownloadProgress().then(
            result => {
                let howMany = 0;
                let done = 0;
                let errorInputs = [];
                let success = [];

                for (const input in result.inputs) {
                    howMany++;
                    const status = result.inputs[input];

                    if (status != "IN_PROGRESS") {
                        done++;

                        if (status == "ERROR") {
                            errorInputs.push(input);
                        } else {
                            component.configurationService.setFileUploadStatus(input, {
                                status: "success",
                            });

                            success.push(input);
                        }
                    }
                }

                let numProgress = (done*100/howMany).toFixed(0);

                progress.next(numProgress);

                if (numProgress == "100") {
                    if (errorInputs.length > 0) {
                        let error = {error: {message: "Problem with preparing following inputs: " + errorInputs.join(", ")
                                    + ". Please upload another files or uncheck indicators from calculation."}};

                        progress.error(error);
                    } else {
                        progress.complete();
                    }
                } else {
                    setTimeout(() => { this.checkDownloadStatus(progress); }, 5000);
                }
            }
        )
    }

    isIndicatorPreselected(indicatorId: string) {
        return this.preSelectedIndicators.includes(indicatorId);
    }

    public hasFoundInGlobalData(inputIds: string[]): boolean {
        if (!this.globalDataCoverage) {
            return false;
        }

        for (const id of inputIds) {
            if (!this.globalDataCoverage[id]) {
                return false;
            }
        }
        return true;
    }

    tabChanged(event: any): void {
        if (event.index == 5) {
            setTimeout(() => {
                this.goalSummary.refreshMap(true);
            }, 100);
        }
    }

    onKeyDown(event: any) {
        if (event.key == 'd' && event.ctrlKey == true) {
            this.showDebug = true;
        }
    }
}


