<div class="content-padding">
    <p>All the input data is prepared. To calculate indicators and correlation matrix please click button below:</p>
    <button mat-flat-button (click)="calculateCorrelationMatrix()" color="primary">
        Calculate indicators correlation matrix
    </button>

    <div *ngIf="correlationMatrixDisplayedColumns && correlationMatrixDisplayedColumns.length > 2">
        <p>The recommendation is to select indicators which correlation values are between -0.7 and 0.7.</p>

        <table mat-table id="correlationMatrix" [dataSource]="correlationMatrixDataSource">
            <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox (change)="changeSelectedIndicator(element.indicatorId, $event)" [checked]="isIndicatorSelected(element.indicatorId)"></mat-checkbox>
                </td>
            </ng-container>
            <ng-container *ngFor="let column of correlationMatrixColumns" [cdkColumnDef]="column.columnDef">
                <th mat-header-cell *cdkHeaderCellDef>{{ column.header }}</th>
                <td mat-cell *cdkCellDef="let row" class="{{ column.classIndicator(column.columnDef) }}">
                    <ng-container *ngIf="column.type == 'name'">{{ column.cell(column.columnDef, row) }}</ng-container>
                    <ng-container *ngIf="column.type == 'value'"><div class="wrapper"><mat-chip-list><mat-chip class="{{ getClassByValue(column.cell(column.columnDef, row)) }}">{{ column.cell(column.columnDef, row) }}</mat-chip></mat-chip-list></div></ng-container>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="correlationMatrixDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: correlationMatrixDisplayedColumns;"></tr>
        </table>
    </div>
</div>
