import { Component, Inject, ViewEncapsulation } from "@angular/core";

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
    title: string;
    content: string;
}

@Component({
    selector: 'yes-no-dialog',
    templateUrl: 'yesno.dialog.component.html',
    styleUrls: [ 'yesno.dialog.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class YesNoDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<YesNoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    yesClick(): void {
        this.dialogRef.close(true);
    }

    noClick(): void {
        this.dialogRef.close(false);
    }
}
