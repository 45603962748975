<form [formGroup]="indicatorsClassDivisionSummaryForm">
    <p>
        Please choose the number of classes and if needed adjust their ranges. Changes in the direction of influence (stimulation or not-stimulation) of the indicator value on the need for water retention should be done only in exceptional situations. Changes in the weight are recommended if a clear dominance of one group of indicators, eg concerning LandUse was observed, then you can reduce their importance by changing the weight.
    </p>
    <mat-form-field>
        <mat-label>
            Number of classes:
        </mat-label>
        <mat-select formControlName="numClasses">
            <mat-option [value]="3">3</mat-option>
            <mat-option [value]="4">4</mat-option>
            <mat-option [value]="5">5</mat-option>
        </mat-select>
        <mat-error>
            Number of classes is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Class division method:</mat-label>
        <mat-select formControlName="divisionMethod">
            <mat-option *ngFor="let classDivision of classDivisionMethods | keyvalue" [value]="classDivision.value">
                {{classDivision.value}}
            </mat-option>
        </mat-select>
        <mat-error>
            Division method is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" class="left-margin" (click)="calculateIndicatorsClassRanges()">Calculate</button>
</form>
<form [formGroup]="indicatorsSummaryForm">
    <table mat-table [dataSource]="indicatorsSummaryDataSource" formArrayName="indicators" multiTemplateDataRows>
        <ng-container matColumnDef="indicatorId">
            <th mat-header-cell *matHeaderCellDef>Indicator</th>
            <td mat-cell *matCellDef="let element">
                {{ element.indicatorId }}
            </td>
        </ng-container>
        <ng-container matColumnDef="statMean">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">Mean</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-centered">{{ element.mean }}</td>
        </ng-container>
        <ng-container matColumnDef="statMin">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">Min</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-centered">{{ element.min }}</td>
        </ng-container>
        <ng-container matColumnDef="statMax">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">Max</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-centered">{{ element.max }}</td>
        </ng-container>
        <ng-container matColumnDef="statStDev">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">St dev</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-centered">{{ element.stDev }}</td>
        </ng-container>
        <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">Unit</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-centered">{{ element.unit }}</td>
        </ng-container>
        <ng-container matColumnDef="stimulationing">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">Stimulationing</th>
            <td mat-cell *matCellDef="let element; let i = dataIndex;" [formGroupName]="i" class="mat-cell-centered">
                <mat-form-field class="class-range">
                    <mat-select formControlName="stimulationing" required (selectionChange)="onStimulationChange(element, i)">
                        <mat-option value="positive">positive</mat-option>
                        <mat-option value="negative">negative</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">Weight</th>
            <td mat-cell *matCellDef="let element; let i = dataIndex;" [formGroupName]="i" class="mat-cell-centered">
                <input type="hidden" formControlName="indicatorId">
                <mat-form-field class="class-range">
                    <input type="number" matInput formControlName="weight" [max]="1" [min]="0" [step]="0.01" required>
                    <mat-error *ngIf="indicatorsSummaryForm.get('indicators.' + i + '.weight').errors?.required">This value must be filled.</mat-error>
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="classRanges">
            <td mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="8" [formGroupName]="i">
                <div formArrayName="ranges" class="ranges">
                    Class ranges:
                    <ng-container *ngFor="let j of indicatorClassDivision; let first = first; let last = last">
                        <mat-form-field class="class-range" [formGroupName]="j">
                            <input matInput type="number" formControlName="range" [readonly]="first || last">
                        </mat-form-field>
                        <span *ngIf="!last">-</span>
                    </ng-container>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['indicatorId', 'statMean', 'statMin', 'statMax', 'statStDev', 'unit', 'stimulationing', 'weight']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['indicatorId', 'statMean', 'statMin', 'statMax', 'statStDev', 'unit', 'stimulationing', 'weight'];"></tr>
        <tr mat-row *matRowDef="let row; columns: ['classRanges'];" class="class-ranges-row"></tr>
    </table>
    <div style="padding:20px;text-align: center">
        <button mat-raised-button color="primary" [disabled]="!this.indicatorsSummaryForm.valid" (click)="save()">Save values</button>
    </div>
</form>
