import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class SessionHttpInterceptor implements HttpInterceptor {
    sessionId: string = "";

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let update = {};

        if (this.sessionId == "" && localStorage.getItem("sessionId") != null) {
            this.sessionId = localStorage.getItem("sessionId");
        }

        if (this.sessionId != "") {
            update = {
                withCredentials: true,
            }
        }

        req = req.clone(update);

        return next.handle(req);
    }
}
