import { OnDestroy, OnInit } from "@angular/core";
import { FrogisService } from "./frogis.service";
import { GoalType, Indicators, Inputs, RecommendedIndicators, SpuType } from "./frogis.model";
import { FormBuilder, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { ProgressDialogComponent } from "../shared/progress-dialog.component";
import { ConfigurationService } from "./configuration.service";
import { Subject } from "rxjs";
import { GoalSummaryComponent } from "./goal-summary.component";
import { CorrelationMatrixComponent } from "./correlation-matrix.component";
import { IndicatorsSummaryComponent } from "./indicators-summary.component";
var FrogisComponent = /** @class */ (function () {
    function FrogisComponent(frogisService, configurationService, dialog) {
        var _this = this;
        this.frogisService = frogisService;
        this.configurationService = configurationService;
        this.dialog = dialog;
        this.step = 0;
        this.showDebug = false;
        this.goalTypes = GoalType;
        this.spuTypes = SpuType;
        this.recommendedIndicatorsForGoal = RecommendedIndicators;
        this.formBuilder = new FormBuilder();
        this.inputs = Inputs;
        this.globalDataCoverage = {};
        this.showInputsPanel = false;
        this.selectedInputIdToUpload = "";
        this.preSelectedIndicators = [];
        this.fileUploadStatus = {};
        this.showIndicator = {};
        this.haveAllInputDataToProcess = false;
        this.haveGoalValues = false;
        this.haveSelectedIndicators = false;
        this.hasSpuFile = false;
        this.spatialPlanningUnitsForm = this.formBuilder.group({
            spuType: ['', Validators.required],
        });
        this.spatialPlanningUnitsForm.valueChanges.subscribe({
            next: function (values) {
                _this.configurationService.setSpuType(values.spuType);
            }
        });
        this.goalAndIndicatorsForm = this.formBuilder.group({
            goal: ['', Validators.required],
        });
        this.goalAndIndicatorsForm.valueChanges.subscribe({
            next: function (values) {
                console.log(values);
                _this.selectedGoal = _this.goalAndIndicatorsForm.get('goal').value;
                _this.configurationService.setGoal(values.goal);
                _this.refreshIndicators(values.goal);
            }
        });
        this.indicatorsDataSource = new MatTableDataSource(Indicators);
        this.configurationService.getSpuType().subscribe({
            next: function (spuType) {
                if (!spuType) {
                    return;
                }
                _this.spatialPlanningUnitsForm.setValue({ spuType: spuType });
            }
        });
        this.configurationService.getGlobalDataCoverage().subscribe({
            next: function (values) {
                _this.globalDataCoverage = values;
            }
        });
        this.configurationService.getGoal().subscribe({
            next: function (goal) {
                if (!goal) {
                    return;
                }
                _this.selectedGoal = goal;
                _this.goalAndIndicatorsForm.setValue({ goal: goal });
                _this.refreshIndicators(goal);
            }
        });
        this.configurationService.getPreselectedIndicators().subscribe({
            next: function (value) { _this.preSelectedIndicators = value; }
        });
        this.configurationService.getFileUploadStatus().subscribe({
            next: function (value) {
                _this.fileUploadStatus = value;
                if (value["SubBasin"] && value.SubBasin.status == "success") {
                    _this.hasSpuFile = true;
                }
            }
        });
        this.configurationService.getHasAllRequiredInputData().subscribe({
            next: function (value) { _this.haveAllInputDataToProcess = value; }
        });
        this.configurationService.getSelectedInputs().subscribe({
            next: function (inputs) {
                _this.showInputsPanel = inputs.length > 0;
                _this.inputsDataSource = new MatTableDataSource(inputs);
            }
        });
        this.configurationService.getSelectedIndicators().subscribe({
            next: function (selectedIndicators) {
                _this.haveSelectedIndicators = selectedIndicators.length > 0;
            }
        });
        this.configurationService.getGoalValues().subscribe({
            next: function (goalValues) {
                _this.haveGoalValues = goalValues.length > 0;
            }
        });
    }
    FrogisComponent.prototype.ngOnInit = function () {
    };
    FrogisComponent.prototype.ngOnDestroy = function () {
    };
    FrogisComponent.prototype.setStep = function (step) {
        this.step = step;
    };
    FrogisComponent.prototype.isIndicatorRecommendedForCurrentGoal = function (indicatorId) {
        if (!this.selectedGoal || !this.recommendedIndicatorsForGoal || !this.recommendedIndicatorsForGoal[this.selectedGoal]) {
            return false;
        }
        return this.recommendedIndicatorsForGoal[this.selectedGoal].includes(indicatorId);
    };
    FrogisComponent.prototype.changePreselectedIndicator = function (indicatorId, event) {
        if (event.checked) {
            this.configurationService.addPreselectedIndicator(indicatorId);
        }
        else {
            this.configurationService.removePreselectedIndicator(indicatorId);
        }
    };
    FrogisComponent.prototype.refreshIndicators = function (goal) {
        for (var i in Indicators) {
            var indicator = Indicators[i];
            this.showIndicator[indicator.id] = this.configurationService.getIndicatorById(indicator.id).goal.includes(GoalType[goal]);
        }
        console.log(this.showIndicator);
    };
    FrogisComponent.prototype.onFilesAdded = function (type) {
        var _this = this;
        var files = (type == 'shapefile') ? this.shapeFile.nativeElement.files : this.rasterFile.nativeElement.files;
        for (var key in files) {
            if (!isNaN(parseInt(key))) {
                this.fileToUpload = files[key];
                break;
            }
        }
        this.filesUploadProgress = this.frogisService.upload(this.fileToUpload, this.selectedInputIdToUpload);
        this.progressDialog = this.dialog.open(ProgressDialogComponent, {
            data: {
                title: "File upload",
                content: "Uploading file: " + this.fileToUpload.name,
                successContent: "File successfully uploaded: " + this.fileToUpload.name,
                buttonName: "Ok",
                progress: this.filesUploadProgress,
                hideOk: true
            },
            height: '200px',
            width: '320px',
        }).componentInstance;
        var component = this;
        this.filesUploadProgress.subscribe({
            complete: function (status) {
                component.configurationService.setFileUploadStatus(component.selectedInputIdToUpload, {
                    status: "success",
                });
                if (component.selectedInputIdToUpload == "SubBasin") {
                    _this.hasSpuFile = true;
                }
            },
            error: function (error) {
                if (component.selectedInputIdToUpload == "SubBasin") {
                    _this.hasSpuFile = false;
                }
            }
        });
    };
    FrogisComponent.prototype.addFiles = function (inputId) {
        this.selectedInputIdToUpload = inputId;
        if (this.configurationService.getInputById(inputId).file == "shapefile") {
            this.shapeFile.nativeElement.click();
        }
        else {
            this.rasterFile.nativeElement.click();
        }
    };
    FrogisComponent.prototype.downloadLayerFromGlobalData = function (inputIds) {
        var _this = this;
        this.frogisService.download(inputIds);
        var progress = new Subject();
        this.progressDialog = this.dialog.open(ProgressDialogComponent, {
            data: {
                title: "Global data",
                content: "Receiving global data",
                successContent: "Received all data",
                buttonName: "Ok",
                progress: progress.asObservable(),
                hideOk: true
            },
            height: '250px',
            width: '520px',
        }).componentInstance;
        setTimeout(function () { _this.checkDownloadStatus(progress); }, 5000);
    };
    FrogisComponent.prototype.checkDownloadStatus = function (progress) {
        var _this = this;
        var component = this;
        this.frogisService.getDownloadProgress().then(function (result) {
            var howMany = 0;
            var done = 0;
            var errorInputs = [];
            var success = [];
            for (var input in result.inputs) {
                howMany++;
                var status_1 = result.inputs[input];
                if (status_1 != "IN_PROGRESS") {
                    done++;
                    if (status_1 == "ERROR") {
                        errorInputs.push(input);
                    }
                    else {
                        component.configurationService.setFileUploadStatus(input, {
                            status: "success",
                        });
                        success.push(input);
                    }
                }
            }
            var numProgress = (done * 100 / howMany).toFixed(0);
            progress.next(numProgress);
            if (numProgress == "100") {
                if (errorInputs.length > 0) {
                    var error = { error: { message: "Problem with preparing following inputs: " + errorInputs.join(", ")
                                + ". Please upload another files or uncheck indicators from calculation." } };
                    progress.error(error);
                }
                else {
                    progress.complete();
                }
            }
            else {
                setTimeout(function () { _this.checkDownloadStatus(progress); }, 5000);
            }
        });
    };
    FrogisComponent.prototype.isIndicatorPreselected = function (indicatorId) {
        return this.preSelectedIndicators.includes(indicatorId);
    };
    FrogisComponent.prototype.hasFoundInGlobalData = function (inputIds) {
        if (!this.globalDataCoverage) {
            return false;
        }
        for (var _i = 0, inputIds_1 = inputIds; _i < inputIds_1.length; _i++) {
            var id = inputIds_1[_i];
            if (!this.globalDataCoverage[id]) {
                return false;
            }
        }
        return true;
    };
    FrogisComponent.prototype.tabChanged = function (event) {
        var _this = this;
        if (event.index == 5) {
            setTimeout(function () {
                _this.goalSummary.refreshMap(true);
            }, 100);
        }
    };
    FrogisComponent.prototype.onKeyDown = function (event) {
        if (event.key == 'd' && event.ctrlKey == true) {
            this.showDebug = true;
        }
    };
    return FrogisComponent;
}());
export { FrogisComponent };
