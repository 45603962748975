import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
Sentry.init({
    dsn: "https://717d203b66104be6a39c721b22f943a9@o512431.ingest.sentry.io/5612520",
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: ["localhost", "retencjawod.sggw.pl"],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],
    tracesSampleRate: 1.0,
});
var ɵ0 = Sentry.createErrorHandler({
    showDialog: true,
});
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
