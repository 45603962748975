import { FrogisService } from "./frogis/frogis.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(frogisService) {
        var _this = this;
        this.frogisService = frogisService;
        this.frogisService.version().then(function (result) {
            _this.version = result.version;
        });
    }
    return AppComponent;
}());
export { AppComponent };
