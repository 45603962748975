import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";

export class ProgressDialogData {
    title: string = "";
    content: string = "";
    successContent: string = "";
    buttonName: string = "";
    url: string = "";
    progress: Observable<number> | null;
    hideOk: boolean = false;
}

@Component({
  selector: 'progress-dialog',
  templateUrl: 'progress-dialog.component.html',
  styleUrls: [ 'progress-dialog.component.css' ],
  encapsulation: ViewEncapsulation.None
})
export class ProgressDialogComponent implements OnInit{
    public showProgress:boolean = true;
    progressType: string = "indeterminate";
    progressValue:number = 0;

    constructor(
        public dialogRef: MatDialogRef<ProgressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProgressDialogData) {
    }

    ngOnInit() {
        if (this.data.progress != null) {
            this.progressType = "determinate";

            const component = this;

            this.data.progress.subscribe({
                next(value) {
                    component.progressValue = value;
                },
                complete() {
                    if (component.data.successContent != "") {
                        component.data.content = component.data.successContent;
                    }

                    component.hideProgress();
                },
                error(data: any) {
                    component.data.title = "Error";
                    component.data.content = data.error.message;

                    component.hideProgress();
                }
            });
        }
    }

    closeClick(): void {
        this.dialogRef.close();
    }

    hideProgress():void {
        this.showProgress = false;
    }
}
