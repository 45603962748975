import { MatTableDataSource } from "@angular/material/table";
import { ConfigurationService } from "./configuration.service";
var DebugComponent = /** @class */ (function () {
    function DebugComponent(configurationService) {
        var _this = this;
        this.configurationService = configurationService;
        this.indicatorsColumns = [];
        this.calculationResults = {};
        this.indicatorClasses = {};
        this.goalValues = [];
        this.goalClasses = [];
        this.configurationService.getCalculationResult().subscribe({
            next: function (value) {
                _this.calculationResults = value;
                _this.showIndicatorsResult();
            }
        });
        this.configurationService.getIndicatorClasses().subscribe({
            next: function (value) {
                _this.indicatorClasses = value;
                _this.showIndicatorsResult();
            }
        });
        this.configurationService.getGoalValues().subscribe({
            next: function (goalValues) {
                _this.goalValues = goalValues;
                _this.showIndicatorsResult();
            }
        });
        this.configurationService.getGoalClasses().subscribe({
            next: function (goalClasses) {
                _this.goalClasses = goalClasses;
                _this.showIndicatorsResult();
            }
        });
    }
    DebugComponent.prototype.showIndicatorsResult = function () {
        var data = [];
        var columns = [];
        var indicators = Object.keys(this.calculationResults);
        var spuNums = 0;
        if (indicators.length > 0) {
            var indicatorId = indicators[0];
            spuNums = this.calculationResults[indicatorId].length;
            for (var i = 0; i < spuNums; i++) {
                data.push({ spu: i + 1 });
            }
        }
        for (var _i = 0, indicators_1 = indicators; _i < indicators_1.length; _i++) {
            var indicatorId = indicators_1[_i];
            var isIndicatorClasses = this.indicatorClasses[indicatorId];
            for (var i = 0; i < spuNums; i++) {
                data[i][indicatorId] = this.calculationResults[indicatorId][i];
                if (isIndicatorClasses) {
                    data[i][indicatorId + "Classes"] = this.indicatorClasses[indicatorId][i];
                }
            }
            columns.push(indicatorId);
            if (isIndicatorClasses) {
                columns.push(indicatorId + "Classes");
            }
        }
        var indicatorsColumns = [
            { columnDef: "spu",
                header: "SPU",
                classIndicator: function (columnIndicatorId) { return "mat-cell"; },
                cell: function (columnIndicatorId, row) { return "" + row["spu"]; },
            }
        ];
        if (data.length > 0) {
            if (this.goalValues && this.goalValues.length > 0) {
                columns.push("goalValue");
                for (var i = 0; i < this.goalValues.length; i++) {
                    data[i]["goalValue"] = this.goalValues[i];
                }
            }
            if (!this.goalClasses) {
                this.goalClasses = [];
            }
            if (this.goalClasses.length > 0) {
                columns.push("goalClass");
                for (var i = 0; i < this.goalClasses.length; i++) {
                    data[i]["goalClass"] = this.goalClasses[i];
                }
            }
            for (var _a = 0, columns_1 = columns; _a < columns_1.length; _a++) {
                var columnId = columns_1[_a];
                indicatorsColumns.push({
                    columnDef: columnId,
                    header: columnId,
                    classIndicator: function (columnIndicatorId) { return "mat-cell show-indicator"; },
                    cell: function (columnIndicatorId, row) { return "" + row[columnIndicatorId]; },
                });
            }
        }
        this.indicatorsColumns = indicatorsColumns;
        this.indicatorsDisplayedColumns = this.indicatorsColumns.map(function (c) { return c.columnDef; });
        this.data = data;
        this.indicatorsDataSource = new MatTableDataSource(data);
    };
    DebugComponent.prototype.reset = function () {
        this.configurationService.reset();
    };
    DebugComponent.prototype.getData = function () {
        return this.data;
    };
    return DebugComponent;
}());
export { DebugComponent };
