<form [formGroup]="goalClassDivisionForm">
    <p>Please select number of classes and division method to see a map with calculated results.</p>
    <mat-form-field>
        <mat-label>
            Number of classes:
        </mat-label>
        <mat-select formControlName="numClasses">
            <mat-option [value]="3">3</mat-option>
            <mat-option [value]="4">4</mat-option>
            <mat-option [value]="5">5</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Class division method:</mat-label>
        <mat-select formControlName="divisionMethod">
            <mat-option *ngFor="let classDivision of classDivisionMethods | keyvalue" [value]="classDivision.value">
                {{classDivision.value}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" class="left-margin" (click)="calculateGoalClassRanges()">Calculate</button>
</form>
<form [formGroup]="goalSummaryForm">
    <table mat-table [dataSource]="goalSummaryDataSource" multiTemplateDataRows *ngIf="goalValues.length > 0">
        <ng-container matColumnDef="statMean">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">Mean</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-centered">{{ element.mean }}</td>
        </ng-container>
        <ng-container matColumnDef="statMin">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">Min</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-centered">{{ element.min }}</td>
        </ng-container>
        <ng-container matColumnDef="statMax">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">Max</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-centered">{{ element.max }}</td>
        </ng-container>
        <ng-container matColumnDef="statStDev">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-centered">St dev</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-centered">{{ element.stDev }}</td>
        </ng-container>
        <ng-container matColumnDef="classRanges">
            <td mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="4">
                <div formArrayName="ranges" class="ranges">
                    Class ranges:
                    <ng-container *ngFor="let j of goalClassRanges; let first = first; let last = last">
                        <mat-form-field class="class-range" [formGroupName]="j">
                            <input matInput type="number" formControlName="range" [readonly]="first || last">
                            <mat-error *ngIf="goalSummaryForm.get('ranges.' + j + '.range').errors?.empty">This value must be filled.</mat-error>
                            <mat-error *ngIf="goalSummaryForm.get('ranges.' + j + '.range').errors?.notMonotonic">This value should be monotonic.</mat-error>
                            <mat-error *ngIf="goalSummaryForm.get('ranges.' + j + '.range').errors?.outOfRange">This value should be within the goal value range.</mat-error>
                        </mat-form-field>
                        <span *ngIf="!last">-</span>
                    </ng-container>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['statMean', 'statMin', 'statMax', 'statStDev']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['statMean', 'statMin', 'statMax', 'statStDev'];"></tr>
        <tr mat-row *matRowDef="let row; columns: ['classRanges'];" class="class-ranges-row"></tr>
    </table>
</form>
<div id="map" [hidden]="!showMap" leaflet [leafletCenter]="leafletCenter" [leafletZoom]="leafletZoom" (leafletMapReady)="onMapReady($event)"></div>
<div style="text-align: center;margin-top:20px;">
<button mat-raised-button color="primary" style="margin-right: 20px" (click)="downloadCsv()">Download report file (.csv)</button>
<button mat-raised-button color="primary" (click)="downloadGeoJSON()">Download shape file (.geojson)</button>
</div>
