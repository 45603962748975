import {Subject} from 'rxjs';

export enum SpuType {
    ELEMENTARY_BASINS = 'elementary basins',
    WATER_BODIES = 'water bodies',
    AGGREGATED_WATER_BODIES = 'aggregated water bodies',
    HYDROLOGIC_RESPONSE_UNIT = 'Hydrologic Response Unit',
}

export enum GoalType {
    GENERAL = 'general',
    DROUGHT = 'drought',
    FLOOD = 'flood',
    WATER_QUALITY = 'water quality',
    SEDIMENT_TRANSPORT = 'sediment transport',
}

export class SimulationConfiguration {
    sessionId = '';
    selectedSpuType: SpuType;
    globalDataCoverage: any = {};
    selectedGoal: GoalType;
    preSelectedIndicators: string[] = [];
    selectedIndicators: string[] = [];
    selectedInputs: Input[] = [];
    calculationResult = {};
    indicatorClasses = {};
    indicatorClassBreaks = {};
    indicatorWeight = {};
    indicatorStimulationing = {};
    fileUploadStatus: any = {};
    indicatorsClassDivision: ClassDivision;
    goalClassDivision: ClassDivision;
    goalValues: number[] = [];
    goalClassBreaks: number[] = [];
    goalClasses: number[] = [];
}

export class ClassDivision {
    constructor(
        public numClasses: number,
        public divisionMethod: DivisionMethod,
    ) {
    }
}

export class Indicator {
    constructor(
        public id: string,
        public name: string,
        public goal: GoalType[],
        public input: string[],
        public unit: string,
        public calculation: string,
        public stimulating: string,
        public topic: string,
        public necessity: string,
    ) {
    }
}

export const Indicators: Indicator[] = [
    {
        id: 'ArableRatio',
        name: 'Arable area in SPU area ratio',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['Arable'],
        unit: '%',
        stimulating: 'positive',
        calculation: 'density',
        topic: 'Landuse',
        necessity: 'N',
    },
    {
        id: 'BFI',
        name: 'Base Flow Index',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL],
        input: ['BFI'],
        unit: '-',
        stimulating: 'negative',
        calculation: 'onePolyToSPU',
        topic: 'Hydrology',
        necessity: 'N',
    },
    {
        id: 'CWB',
        name: 'Climatic Water Balance',
        goal: [GoalType.DROUGHT, GoalType.GENERAL],
        input: ['CWB'],
        unit: 'mm',
        stimulating: 'negative',
        calculation: 'oneRasterToSPU',
        topic: 'Climate',
        necessity: 'N',
    },
    {
        id: 'DrainageD',
        name: 'Drainage Density',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['River'],
        unit: 'km/km2',
        stimulating: 'positive',
        calculation: 'drainage',
        topic: 'Hydrography',
        necessity: 'P',
    },
    {
        id: 'EcoAraBuf20mRatio',
        name: 'Arable lands in 20-meters buffer around surface waters area to SPU area ratio',
        goal: [GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['River', 'Lake', 'Arable'],
        unit: '-',
        stimulating: 'positive',
        calculation: 'ecoAraBuf20mRatio',
        topic: 'Ecology',
        necessity: 'N',
    },
    {
        id: 'EcoAreaRatio',
        name: 'Semi-natural land cover types area to SPU area ratio',
        goal: [GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['SemiNatural'],
        unit: '%',
        stimulating: 'negative',
        calculation: 'density',
        topic: 'Ecology',
        necessity: 'N',
    },
    {
        id: 'EcoBadRHS',
        name: 'Bad morphological  elements length to total length of river in SPU',
        goal: [GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['River', 'RHS'],
        unit: '%',
        stimulating: 'positive',
        calculation: 'ecoBadRHS',
        topic: 'Ecology',
        necessity: 'N',
    },
    {
        id: 'EcoCombined',
        name: 'Combination of number of semi-natural land cover patches and their area',
        goal: [GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['LandUse', 'SemiNatural'],
        unit: '-',
        stimulating: 'negative',
        calculation: 'ecoCombined',
        topic: 'Ecology',
        necessity: 'N',
    },
    {
        id: 'EcoNumRatio',
        name: 'Number of semi-natural land cover patches to total number of land cover patches in SPU',
        goal: [GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['LandUse', 'SemiNatural'],
        unit: '%',
        stimulating: 'negative',
        calculation: 'ecoNumRatio',
        topic: 'Ecology',
        necessity: 'N',
    },
    {
        id: 'FloodRiskAreaRatio',
        name: 'Flood hazard zone area ratio',
        goal: [GoalType.FLOOD, GoalType.GENERAL],
        input: ['FloodExtent'],
        unit: '%',
        stimulating: 'positive',
        calculation: 'density',
        topic: 'Hydrology',
        necessity: 'P',
    },
    {
        id: 'FlowMinAvgRatio',
        name: 'Mean low flow to mean flow ratio',
        goal: [GoalType.DROUGHT, GoalType.GENERAL],
        input: ['SwFlow'],
        unit: '-',
        calculation: 'ratio',
        stimulating: 'negative',
        topic: 'Hydrology',
        necessity: 'N',
    },
    {
        id: 'FlowMaxAvgRatio',
        name: 'Ratio of high low flow to mean flow in the multiannual period',
        goal: [GoalType.FLOOD, GoalType.GENERAL],
        input: ['SwFlow'],
        unit: '-',
        stimulating: 'positive',
        calculation: 'ratio',
        topic: 'Hydrology',
        necessity: 'P',
    },
    {
        id: 'FlowMinMaxRatio',
        name: 'Mean low flow to mean high flow ratio',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL],
        input: ['SwFlow'],
        unit: '-',
        stimulating: 'negative',
        calculation: 'ratio',
        topic: 'Hydrology',
        necessity: 'NP',
    },
    {
        id: 'FlowVarRatio_m',
        name: 'Low mean flow to hight mean flow ratio',
        goal: [GoalType.DROUGHT, GoalType.GENERAL],
        input: ['SwFlow'],
        unit: '-',
        calculation: 'ratio',
        stimulating: 'negative',
        topic: 'Hydrology',
        necessity: 'P',
    },
    {
        id: 'ForestRatio',
        name: 'Forested area to SPU area ratio',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.SEDIMENT_TRANSPORT, GoalType.WATER_QUALITY],
        input: ['Forest'],
        unit: '%',
        stimulating: 'negative',
        calculation: 'density',
        topic: 'Landuse',
        necessity: 'N',
    },
    {
        id: 'GRR',
        name: 'Groundwater Renewable Resources Module',
        goal: [GoalType.DROUGHT, GoalType.GENERAL],
        input: ['GRR'],
        unit: 'mm',
        stimulating: 'negative',
        calculation: 'oneRasterToSPU',
        topic: 'Hydrogeology',
        necessity: 'N',
    },
    {
        id: 'LakeRatio',
        name: 'Lakes and reservoirs area to SPU area ratio',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY],
        calculation: 'density',
        input: ['Lake'],
        unit: '%',
        stimulating: 'negative',
        topic: 'Hydrography',
        necessity: 'N',
    },
    {
        id: 'LakeCatchRatio',
        name: 'Lake catchment area to SPU area ratio',
        goal: [GoalType.FLOOD, GoalType.GENERAL],
        input: ['LakeCatch'],
        unit: '%',
        stimulating: 'negative',
        calculation: 'density',
        topic: 'Hydrography',
        necessity: 'N',
    },
    {
        id: 'LandSlope',
        name: 'Slope of land area',
        goal: [GoalType.SEDIMENT_TRANSPORT, GoalType.GENERAL, GoalType.FLOOD],
        input: ['DEM'],
        unit: 'deg',
        stimulating: 'positive',
        calculation: 'slopeLand',
        topic: 'Topography',
        necessity: 'P',
    },
    {
        id: 'MeanderRatio',
        name: 'MeanderRatio',
        goal: [GoalType.SEDIMENT_TRANSPORT, GoalType.GENERAL, GoalType.FLOOD],
        input: ['River'],
        unit: '%',
        stimulating: 'positive',
        calculation: 'meanderRatio',
        topic: 'Hydrography',
        necessity: 'N',
    },
    {
        id: 'NonForestedRatio',
        name: 'Non forested area with a slope above 5% to SPU area ratio',
        input: ['NonForest', 'DEM'],
        goal: [GoalType.FLOOD, GoalType.GENERAL, GoalType.SEDIMENT_TRANSPORT, GoalType.WATER_QUALITY],
        unit: '%',
        stimulating: 'positive',
        calculation: 'nonForested',
        topic: 'Ecology',
        necessity: 'P',
    },
    {
        id: 'Pre_Var_a',
        name: 'Precipitation variability in annual period - amplitude of monthly sum of (pMax_i - pMin_i)/pAvg_i',
        goal: [GoalType.DROUGHT, GoalType.GENERAL],
        input: ['PrecVeg'],
        unit: '-',
        stimulating: 'positive',
        calculation: 'onePointToSPU',
        topic: 'Climate',
        necessity: 'P',
    },
    {
        id: 'Pre_Var_m',
        name: 'Precipitation variability in the multiannual period [pMin]/[P]',
        goal: [GoalType.DROUGHT, GoalType.GENERAL],
        input: ['PrecVeg'],
        unit: '-',
        stimulating: 'negative',
        calculation: 'preVarM',
        topic: 'Climate',
        necessity: 'N',
    },
    {
        id: 'PrecFreqLow75',
        name: 'Frequency of precipitation lower than 50% of the multiannual average (in the growing season)',
        goal: [GoalType.DROUGHT, GoalType.GENERAL],
        input: ['PrecVeg'],
        unit: '-',
        stimulating: 'positive',
        calculation: 'onePointToSPU',
        topic: 'Climate',
        necessity: 'N',
    },
    {
        id: 'OrchVegRatio',
        name: 'Orchards & vegetable farming area to SPU area ratio',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['Orchard'],
        unit: '%',
        stimulating: 'positive',
        calculation: 'density',
        topic: 'Landuse',
        necessity: 'N',
    },
    {
        id: 'RainFallErodibility',
        name: 'Rainfall Erodibility',
        goal: [GoalType.FLOOD, GoalType.GENERAL, GoalType.SEDIMENT_TRANSPORT],
        input: ['PrecAnn', 'PrecVeg'],
        unit: '(MJ mm) / (ha hr y)',
        stimulating: 'positive',
        calculation: 'rainfallErodibility',
        topic: 'Climate',
        necessity: 'N',
    },
    {
        id: 'ReclaimedRatio',
        name: 'Reclaimed meadows and pastures area to SPU area ratio',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['Ditches', 'MeadPasture'],
        unit: '%',
        stimulating: 'positive',
        calculation: 'reclaimedRatio',
        topic: 'Hydrography',
        necessity: 'NP',
    },
    {
        id: 'RiverSlope',
        name: 'Slope of river',
        goal: [GoalType.FLOOD, GoalType.SEDIMENT_TRANSPORT, GoalType.GENERAL],
        input: ['DEM', 'River'],
        unit: '',
        stimulating: 'positive',
        calculation: 'slopeRiver',
        topic: 'Topography',
        necessity: 'NP',
    },
    {
        id: 'SoilErodibility',
        name: 'SoilErodibility',
        goal: [GoalType.FLOOD, GoalType.SEDIMENT_TRANSPORT, GoalType.GENERAL],
        input: ['SoilSand', 'SoilSilt', 'SoilClay', 'SoilOrgC'],
        stimulating: 'positive',
        calculation: 'soilErodibility',
        unit: '(t ha hr) / (MJ ha mm)',
        topic: 'Soil',
        necessity: 'N',
    },
    {
        id: 'SRI',
        name: 'Surface Runoff Index',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL],
        input: ['PrecAnn', 'SwFlow'],
        unit: '-',
        stimulating: 'positive',
        calculation: 'sri',
        topic: 'Hydrology',
        necessity: 'P',
    },
    {
        id: 'SWR',
        name: 'Maximum soil water retention',
        goal: [GoalType.DROUGHT, GoalType.GENERAL],
        input: ['SWR'],
        unit: 'mm',
        stimulating: 'negative',
        calculation: 'oneRasterToSPU',
        topic: 'soil',
        necessity: 'N',
    },
    {
        id: 'TWI',
        name: 'Topographic Wetness Index',
        goal: [GoalType.FLOOD, GoalType.DROUGHT, GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['DEM'],
        unit: '-',
        stimulating: 'negative',
        calculation: 'twi',
        topic: 'Topography',
        necessity: 'N',
    },
    {
        id: 'UrbanRatio',
        name: 'Urban area to SPU area ratio',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['Urban'],
        unit: '%',
        stimulating: 'positive',
        calculation: 'density',
        topic: 'Landuse',
        necessity: 'NP',
    },
    {
        id: 'UserDefinedRaster1',
        name: 'User defined raster indicator 1',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY, GoalType.SEDIMENT_TRANSPORT],
        input: ['UserDefinedRaster1'],
        unit: '',
        stimulating: 'positive',
        calculation: 'oneRasterToSPU',
        topic: '',
        necessity: '',
    },
    {
        id: 'UserDefinedRaster2',
        name: 'User defined raster indicator 2',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY, GoalType.SEDIMENT_TRANSPORT],
        input: ['UserDefinedRaster2'],
        unit: '',
        stimulating: 'positive',
        calculation: 'oneRasterToSPU',
        topic: '',
        necessity: '',
    },
    {
        id: 'UserDefinedRaster3',
        name: 'User defined raster indicator 3',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY, GoalType.SEDIMENT_TRANSPORT],
        input: ['UserDefinedRaster3'],
        unit: '',
        stimulating: 'positive',
        calculation: 'oneRasterToSPU',
        topic: '',
        necessity: ''
    },
    {
        id: 'UserDefinedRaster4',
        name: 'User defined raster indicator 4',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY, GoalType.SEDIMENT_TRANSPORT],
        input: ['UserDefinedRaster4'],
        unit: '',
        stimulating: 'positive',
        calculation: 'oneRasterToSPU',
        topic: '',
        necessity: ''
    },
    {
        id: 'UserDefinedPolygon1',
        name: 'User defined polygon indicator 1',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY, GoalType.SEDIMENT_TRANSPORT],
        input: ['UserDefinedPolygon1'],
        unit: '',
        stimulating: 'positive',
        calculation: 'oneRasterToSPU',
        topic: '',
        necessity: '',
    },
    {
        id: 'UserDefinedPolygon2',
        name: 'User defined polygon indicator 2',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY, GoalType.SEDIMENT_TRANSPORT],
        input: ['UserDefinedPolygon2'],
        unit: '',
        stimulating: 'positive',
        calculation: 'oneRasterToSPU',
        topic: '',
        necessity: '',
    },
    {
        id: 'UserDefinedPolygon3',
        name: 'User defined polygon indicator 3',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY, GoalType.SEDIMENT_TRANSPORT],
        input: ['UserDefinedPolygon3'],
        unit: '',
        stimulating: 'positive',
        calculation: 'oneRasterToSPU',
        topic: '',
        necessity: '',
    },
    {
        id: 'UserDefinedPolygon4',
        name: 'User defined polygon indicator 4',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY, GoalType.SEDIMENT_TRANSPORT],
        input: ['UserDefinedPolygon4'],
        unit: '',
        stimulating: 'positive',
        calculation: 'oneRasterToSPU',
        topic: '',
        necessity: '',
    },
    {
        id: 'UserDefinedPolyline1',
        name: 'User defined polygon indicator 1',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY, GoalType.SEDIMENT_TRANSPORT],
        input: ['UserDefinedPolyline1'],
        unit: '',
        stimulating: 'positive',
        calculation: 'ecoBadRHS',
        topic: '',
        necessity: '',
    },
    {
        id: 'UserDefinedPolyline2',
        name: 'User defined polyline indicator 2',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY, GoalType.SEDIMENT_TRANSPORT],
        input: ['UserDefinedPolyline2'],
        unit: '',
        stimulating: 'positive',
        calculation: 'ecoBadRHS',
        topic: '',
        necessity: '',
    },
    {
        id: 'WaterYieldAvgFlow',
        name: 'Water yield (specific runoff) for mean flow n the multiannual period',
        goal: [GoalType.DROUGHT, GoalType.GENERAL],
        input: ['SwFlow'],
        unit: 'mm',
        stimulating: 'negative',
        calculation: 'onePolyToSPU',
        topic: 'Hydrology',
        necessity: 'N',
    },
    {
        id: 'WaterYieldMinFlow',
        name: 'Water yield (specific runoff) for low flow in the multiannual period',
        goal: [GoalType.DROUGHT, GoalType.GENERAL],
        input: ['SwFlow'],
        unit: 'mm',
        stimulating: 'negative',
        calculation: 'onePolyToSPU',
        topic: 'Hydrology',
        necessity: 'N',
    },
    {
        id: 'WetlandRatio',
        name: 'Wetland area to SPU area ratio',
        goal: [GoalType.DROUGHT, GoalType.FLOOD, GoalType.GENERAL, GoalType.WATER_QUALITY],
        input: ['Wetland'],
        unit: '%',
        stimulating: 'negative',
        calculation: 'density',
        topic: 'Landuse',
        necessity: 'N',
    }
];

export const RecommendedIndicators = {
    DROUGHT: ['Pre_Var_a', 'Pre_Var_m', 'SWR', 'SRI', 'FlowMinMaxRatio', 'DrainageD', 'TWI', 'ForestRatio', 'LakeRatio',
        'WetlandRatio', 'OrchVegRatio', 'UrbanRatio', 'ArableRatio', 'ReclaimedRatio'],
    FLOOD: ['SRI', 'FlowMinMaxRatio', 'RiverSlope', 'LandSlope', 'MeanderRatio', 'DrainageD', 'TWI', 'ForestRatio',
        'WetlandRatio', 'UrbanRatio', 'ArableRatio', 'ReclaimedRatio'],
    QUALITY: ['DrainageD', 'TWI', 'ForestRatio', 'WetlandRatio', 'UrbanRatio', 'ArableRatio', 'ReclaimedRatio',
        'EcoAreaRatio', 'EcoNumRatio'],
    WATER_QUALITY: ['Pre_Var_a', 'PrecFreqLow75', 'SWR', 'GRR', 'SRI', 'FlowMinMaxRatio', 'WaterYieldAvgFlow',
        'LandSlope, MeanderRatio', 'DrainageD', 'ForestRatio', 'LakeRatio', 'WetlandRatio', 'UrbanRatio', 'ArableRatio',
        'ReclaimedRatio', 'EcoAreaRatio', 'EcoNumRatio'],
    SEDIMENT_TRANSPORT: ['GraniteRatio', 'RainfallErodibility', 'SoilErodibility', 'ForestRatio', 'NonForestedRatio',
        'LandSlope', 'MeanderRatio']
};

export class Input {
    constructor(
        public id: string,
        public name: string,
        public format: string,
        public file: string,
        public description: string,
        public unit: string,
        public attributes: string[],
    ) {
    }
}

export const Inputs: Input[] = [
    {
        id: 'SubBasin',
        name: 'Subbasin area',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: [],
    },
    {
        id: 'Arable',
        name: 'Arable layer',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: [],
    },
    {
        id: 'BFI',
        name: 'BaseFlow Index',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: ['bfi'],
    },
    {
        id: 'CWB',
        name: 'Avarage Climatic Water Balance',
        format: '',
        file: 'raster',
        description: 'Avarage Climatic Water Balance during the growing season [mm]',
        unit: 'mm',
        attributes: [],
    },
    {
        id: 'DEM',
        name: 'Digital Elevation Model [m a.s.l.]',
        format: '',
        file: 'raster',
        description: '',
        unit: '',
        attributes: [],
    },
    {
        id: 'Ditches',
        name: 'Ditches',
        format: 'polyline',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: [],
    },
    {
        id: 'FloodExtent',
        name: 'Flood extent (e.g. probability 1% (100 years))',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: [],
    },
    {
        id: 'FloodPlain',
        name: 'Floodplain',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: [],
    },
    {
        id: 'Forest',
        name: 'Forest layer',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: [],
    },
    {
        id: 'GRR',
        name: 'Groundwater Renewable Resources Module [mm]',
        format: '',
        file: 'raster',
        description: '',
        unit: 'mm',
        attributes: [],
    },
    {
        id: 'Lake',
        name: 'Lake',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: [],
    },
    {
        id: 'LakeCatch',
        name: 'Lake Catchment',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: [],
    },
    {
        id: 'MeadPasture',
        name: 'Meadows and pastures',
        format: 'polygon',
        file: 'shapefile',
        description: 'Meadows and pastures (CLC code: 231)',
        unit: '',
        attributes: [],
    },
    {
        id: 'NonForest',
        name: 'NonForest layer',
        format: 'polygon',
        file: 'shapefile',
        description: 'NonForest layer',
        unit: '',
        attributes: [],
    },
    {
        id: 'LandUse',
        name: 'Landuse layer',
        format: 'polygon',
        file: 'shapefile',
        description: 'Landuse layer (e.g. CLC)',
        unit: '',
        attributes: [],
    },
    {
        id: 'Orchard',
        name: 'Orchard layer',
        format: 'polygon',
        file: 'shapefile',
        description: 'Orchard layer (e.g. in CLC code:222)',
        unit: '-',
        attributes: [],
    },
    {
        id: 'Pasture',
        name: 'Pasture',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: [],
    },
    {
        id: 'PrecAnn',
        name: 'Precipitation Station data with annual statistics [mm]',
        format: 'point',
        file: 'shapefile',
        description: '',
        unit: 'mm',
        attributes: ['pAvgAnn'],
    },
    {
        id: 'PrecStat.PrecSumAnn',
        name: 'Precipitation Station - Annual precipitation - mean from multiannual value.',
        format: 'point',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: [],
    },
    {
        id: 'PrecVeg',
        name: 'Precipitation Station data with growing season statistics [mm]',
        format: 'point',
        file: 'shapefile',
        description: '',
        unit: 'mm',
        attributes: ['pMinVeg', 'pAvgVeg', 'preVar_a', 'pFreqLow75'],
    },
    {
        id: 'RHS',
        name: 'River Hydromorphology Status',
        format: 'polyline',
        file: 'shapefile',
        description: 'River Hydromorphology Status (assessed and not assessed river sections (e.g. by River Habitat Survey)) [-]',
        unit: '-',
        attributes: [],
    },
    {
        id: 'River',
        name: 'River',
        format: 'polyline',
        file: 'shapefile',
        description: '',
        unit: '',
        attributes: [],
    },
    {
        id: 'SubCatch',
        name: 'River subbasin (subcatchment)',
        format: 'polyline',
        file: 'shapefile',
        description: '',
        unit: '',
        attributes: [],
    },
    {
        id: 'SemiNatural',
        name: 'Semi-natural land',
        format: 'polygon',
        file: 'shapefile',
        description: 'Semi-natural land (CLC code: 231,311,312,313,321,322,323,324,331,332,333,334,335,411,412,421,422,423)',
        unit: '-',
        attributes: [],
    },
    {
        id: 'SoilSand',
        name: 'Sand',
        format: '',
        file: 'raster',
        description: '',
        unit: '',
        attributes: [],
    },
    {
        id: 'SoilSilt',
        name: 'Silt',
        format: '',
        file: 'raster',
        description: '',
        unit: '',
        attributes: [],
    },
    {
        id: 'SoilClay',
        name: 'Clay',
        format: '',
        file: 'raster',
        description: '',
        unit: '',
        attributes: [],
    },
    {
        id: 'SoilOrgC',
        name: 'Organic Matter',
        format: '',
        file: 'raster',
        description: '',
        unit: '',
        attributes: [],
    },
    {
        id: 'SwFlow',
        name: 'Surface water multiannual flow characteristics [mm]',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '-',
        attributes: ['swMLQ', 'swMMQ', 'swMHQ', 'swLMQ', 'swHMQ'],
    },
    {
        id: 'SWR',
        name: 'Soil Water Retention',
        format: '',
        file: 'raster',
        description: 'Soil Water Retention in 1m depth (maximum or available for plants 2-4.2 for pF) [mm]',
        unit: 'mm',
        attributes: [],
    },
    {
        id: 'Urban',
        name: 'Urban layer',
        format: 'polygon',
        file: 'shapefile',
        description: 'Urban layer (e.g. in CLC codes:111,112,121,122,123,124)',
        unit: '-',
        attributes: [],
    },
    {
        id: 'UserDefinedRaster1',
        name: 'User defined raster 1',
        format: '',
        file: 'raster',
        description: '',
        unit: '*',
        attributes: [],
    },
    {
        id: 'UserDefinedRaster2',
        name: 'User defined raster 2',
        format: '',
        file: 'raster',
        description: '',
        unit: '*',
        attributes: [],
    },
    {
        id: 'UserDefinedRaster3',
        name: 'User defined raster 3',
        format: '',
        file: 'raster',
        description: '',
        unit: '*',
        attributes: [],
    },
    {
        id: 'UserDefinedRaster4',
        name: 'User defined raster 4',
        format: '',
        file: 'raster',
        description: '',
        unit: '*',
        attributes: [],
    },
    {
        id: 'UserDefinedPolygon1',
        name: 'User defined polygon 1',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '*',
        attributes: [],
    },
    {
        id: 'UserDefinedPolygon2',
        name: 'User defined polygon 2',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '*',
        attributes: [],
    },
    {
        id: 'UserDefinedPolygon3',
        name: 'User defined polygon 3',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '*',
        attributes: [],
    },
    {
        id: 'UserDefinedPolygon4',
        name: 'User defined polygon 4',
        format: 'polygon',
        file: 'shapefile',
        description: '',
        unit: '*',
        attributes: [],
    },
    {
        id: 'UserDefinedPolyline1',
        name: 'User defined polyline 1',
        format: 'polyline',
        file: 'shapefile',
        description: '',
        unit: '*',
        attributes: [],
    },
    {
        id: 'UserDefinedPolyline2',
        name: 'User defined polyline 2',
        format: 'polyline',
        file: 'shapefile',
        description: '',
        unit: '*',
        attributes: [],
    },
    {
        id: 'Wetland',
        name: 'Wetland layer',
        format: 'polygon',
        file: 'shapefile',
        description: 'Wetland layer (e.g. in CLC code:411,412,421,422,423)',
        unit: '-',
        attributes: [],
    }
];

export class IndicatorSummary {
    public indicatorId: string;
    public mean: string;
    public min: string;
    public max: string;
    public stDev: string;
    public unit: string;
    public defaultStimulating: string;
    public currentStimulating: string;
    public breaks: number[];
    public weight: number;
}

export enum DivisionMethod {
    EQUAL = 'equal width',
    JENKS = 'natural breaks',
    QUANTILE = 'quantile',
}
