import { MatToolbarModule, MatMenuModule, MatButtonModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { environment } from '../environments/environment';
import { FrogisModule } from "./frogis/frogis.module";
import {MarkdownModule} from "ngx-markdown";
import {SessionHttpInterceptor} from "./auth/session-http-interceptor.service";
import * as Sentry from "@sentry/angular";
import {Integrations} from "@sentry/tracing";

Sentry.init({
    dsn: "https://717d203b66104be6a39c721b22f943a9@o512431.ingest.sentry.io/5612520" ,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: ["localhost", "retencjawod.sggw.pl"],
            routingInstrumentation: Sentry.routingInstrumentation,

        }),
    ],
    tracesSampleRate: 1.0,
});

@NgModule({
    declarations: [
        AppComponent
    ],
    exports: [
        ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FrogisModule,
        HttpClientModule,
        MarkdownModule.forRoot(),
        MatToolbarModule,
        MatButtonModule,
        MatMenuModule,
        SharedModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionHttpInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
    ],
    bootstrap: [ AppComponent ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
