import { Indicators, Inputs, SimulationConfiguration, } from "./frogis.model";
import { Subject } from "rxjs";
import { FrogisService } from "./frogis.service";
import * as i0 from "@angular/core";
import * as i1 from "./frogis.service";
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(frogisService) {
        var _this = this;
        this.frogisService = frogisService;
        this.hasAllRequiredInputData = new Subject();
        this.preselectedIndicators = new Subject();
        this.selectedSpuType = new Subject();
        this.globalDataCoverage = new Subject();
        this.selectedGoal = new Subject();
        this.selectedInputs = new Subject();
        this.selectedIndicators = new Subject();
        this.calculationResult = new Subject();
        this.indicatorClasses = new Subject();
        this.fileUploadStatus = new Subject();
        this.goalClassDivision = new Subject();
        this.goalClassBreaks = new Subject();
        this.goalValues = new Subject();
        this.goalClasses = new Subject();
        this.indicatorClassDivisionConfig = new Subject();
        console.log('configuration ini');
        var config = localStorage.getItem("frogisConfiguration");
        if (config) {
            this.configuration = JSON.parse(config);
            localStorage.setItem("sessionId", this.configuration.sessionId);
        }
        else {
            this.configuration = new SimulationConfiguration();
        }
        console.log(this.configuration);
        this.hello(false);
        this.frogisService.getGlobalDataCoverage().subscribe({
            next: function (values) {
                _this.setGlobalDataCoverage(values);
            }
        });
    }
    ConfigurationService.prototype.reset = function () {
        this.hello(true);
    };
    ConfigurationService.prototype.hello = function (reset) {
        var _this = this;
        this.frogisService.hello(localStorage.getItem("sessionId"), reset).then(function (response) {
            if (response.reset) {
                _this.configuration = new SimulationConfiguration();
            }
            localStorage.setItem("sessionId", response.sessionId);
            _this.configuration.sessionId = response.sessionId;
            _this.configurationSave();
            _this.onSpuTypeChange();
            _this.onGlobalDataCoverage();
            _this.onGoalChange();
            _this.onChangePreselectedIndicator();
            _this.onFileUploadStatus();
            _this.onCalculationResult();
            _this.onSelectedIndicatorChange();
            _this.onIndicatorsClassDivisionChange();
            _this.onIndicatorClassesChange();
            _this.onGoalValuesChange();
            _this.onGoalClassesChange();
            _this.onGoalClassDivision();
            _this.onGoalClassBreaks();
        });
    };
    ConfigurationService.prototype.setSpuType = function (spuType) {
        this.configuration.selectedSpuType = spuType;
        this.configurationSave();
    };
    ConfigurationService.prototype.getSpuType = function () {
        return this.selectedSpuType;
    };
    ConfigurationService.prototype.onSpuTypeChange = function () {
        this.selectedSpuType.next(this.configuration.selectedSpuType);
    };
    ConfigurationService.prototype.setGlobalDataCoverage = function (globalDataCoverage) {
        this.configuration.globalDataCoverage = globalDataCoverage;
        this.configurationSave();
        this.onGlobalDataCoverage();
    };
    ConfigurationService.prototype.getGlobalDataCoverage = function () {
        return this.globalDataCoverage.asObservable();
    };
    ConfigurationService.prototype.onGlobalDataCoverage = function () {
        this.globalDataCoverage.next(this.configuration.globalDataCoverage);
    };
    ConfigurationService.prototype.setGoal = function (goal) {
        this.configuration.selectedGoal = goal;
        this.configurationSave();
    };
    ConfigurationService.prototype.getGoal = function () {
        return this.selectedGoal.asObservable();
    };
    ConfigurationService.prototype.onGoalChange = function () {
        this.selectedGoal.next(this.configuration.selectedGoal);
    };
    ConfigurationService.prototype.addPreselectedIndicator = function (indicatorId) {
        this.configuration.preSelectedIndicators.push(indicatorId);
        this.onChangePreselectedIndicator();
    };
    ConfigurationService.prototype.removePreselectedIndicator = function (indicatorId) {
        this.configuration.preSelectedIndicators = this.configuration.preSelectedIndicators.filter(function (item) { return item !== indicatorId; });
        this.onChangePreselectedIndicator();
    };
    ConfigurationService.prototype.onChangePreselectedIndicator = function () {
        var inputIds = new Set();
        var inputs = [];
        for (var _i = 0, _a = this.configuration.preSelectedIndicators; _i < _a.length; _i++) {
            var indicatorId = _a[_i];
            for (var _b = 0, _c = this.getIndicatorById(indicatorId).input; _b < _c.length; _b++) {
                var inputId = _c[_b];
                if (inputId.indexOf("$") === -1) {
                    inputIds.add(inputId);
                }
            }
        }
        for (var _d = 0, _e = Array.from(inputIds); _d < _e.length; _d++) {
            var inputId = _e[_d];
            inputs.push(this.getInputById(inputId));
        }
        this.configuration.selectedInputs = inputs;
        this.configurationSave();
        this.preselectedIndicators.next(this.configuration.preSelectedIndicators);
        this.selectedInputs.next(inputs);
        this.onFileUploadStatus();
    };
    ConfigurationService.prototype.getPreselectedIndicators = function () {
        return this.preselectedIndicators.asObservable();
    };
    ConfigurationService.prototype.getSelectedInputs = function () {
        return this.selectedInputs.asObservable();
    };
    ConfigurationService.prototype.addSelectedIndicator = function (indicatorId) {
        this.configuration.selectedIndicators.push(indicatorId);
        this.configurationSave();
        this.onSelectedIndicatorChange();
    };
    ConfigurationService.prototype.removeSelectedIndicator = function (indicatorId) {
        this.configuration.selectedIndicators = this.configuration.selectedIndicators.filter(function (item) { return item !== indicatorId; });
        this.configurationSave();
        this.onSelectedIndicatorChange();
    };
    ConfigurationService.prototype.getSelectedIndicators = function () {
        return this.selectedIndicators.asObservable();
    };
    ConfigurationService.prototype.onSelectedIndicatorChange = function () {
        this.selectedIndicators.next(this.configuration.selectedIndicators);
    };
    ConfigurationService.prototype.setFileUploadStatus = function (inputId, status) {
        this.configuration.fileUploadStatus[inputId] = status;
        this.configurationSave();
        this.onFileUploadStatus();
    };
    ConfigurationService.prototype.getFileUploadStatus = function () {
        return this.fileUploadStatus.asObservable();
    };
    ConfigurationService.prototype.getHasAllRequiredInputData = function () {
        return this.hasAllRequiredInputData.asObservable();
    };
    ConfigurationService.prototype.onFileUploadStatus = function () {
        this.fileUploadStatus.next(this.configuration.fileUploadStatus);
        if (this.configuration.preSelectedIndicators.length == 0) {
            this.hasAllRequiredInputData.next(false);
            return;
        }
        var toCheck = this.configuration.selectedInputs;
        if (toCheck.filter(function (item) { return item.id == "SubBasin"; }).length == 0) {
            toCheck.push(this.getInputById("SubBasin"));
        }
        //has only spu
        if (toCheck.length == 1) {
            this.hasAllRequiredInputData.next(false);
            return;
        }
        for (var _i = 0, toCheck_1 = toCheck; _i < toCheck_1.length; _i++) {
            var input = toCheck_1[_i];
            if (!this.configuration.fileUploadStatus.hasOwnProperty(input.id) || this.configuration.fileUploadStatus[input.id].status != "success") {
                this.hasAllRequiredInputData.next(false);
                return;
            }
        }
        this.hasAllRequiredInputData.next(true);
    };
    ConfigurationService.prototype.setCalculationResult = function (result) {
        this.configuration.calculationResult = result;
        this.configurationSave();
        this.onCalculationResult();
    };
    ConfigurationService.prototype.getCalculationResult = function () {
        return this.calculationResult.asObservable();
    };
    ConfigurationService.prototype.onCalculationResult = function () {
        this.calculationResult.next(this.configuration.calculationResult);
    };
    ConfigurationService.prototype.setIndicatorsClassDivisionConfiguration = function (classDivision) {
        this.configuration.indicatorsClassDivision = classDivision;
        this.configurationSave();
    };
    ConfigurationService.prototype.setIndicatorClasses = function (indicatorId, classes) {
        this.configuration.indicatorClasses[indicatorId] = classes;
        this.configurationSave();
        this.onIndicatorClassesChange();
    };
    ConfigurationService.prototype.setClassDivisors = function (indicatorId, breaks, weight, stimulationing) {
        this.configuration.indicatorClassBreaks[indicatorId] = breaks;
        this.configuration.indicatorWeight[indicatorId] = weight;
        this.configuration.indicatorStimulationing[indicatorId] = stimulationing;
        this.configurationSave();
    };
    ConfigurationService.prototype.setGoalClassBreaks = function (breaks) {
        this.configuration.goalClassBreaks = breaks;
        this.configurationSave();
    };
    ConfigurationService.prototype.onIndicatorsClassDivisionChange = function () {
        this.indicatorClassDivisionConfig.next({
            indicatorsClassDivision: this.configuration.indicatorsClassDivision,
            indicatorClassBreaks: this.configuration.indicatorClassBreaks,
            indicatorWeight: this.configuration.indicatorWeight,
            indicatorStimulationing: this.configuration.indicatorStimulationing,
        });
    };
    ConfigurationService.prototype.setGoalValues = function (values) {
        this.configuration.goalValues = values;
        this.configurationSave();
        this.onGoalValuesChange();
    };
    ConfigurationService.prototype.onGoalValuesChange = function () {
        this.goalValues.next(this.configuration.goalValues);
    };
    ConfigurationService.prototype.getGoalValues = function () {
        return this.goalValues.asObservable();
    };
    ConfigurationService.prototype.setGoalClasses = function (values) {
        this.configuration.goalClasses = values;
        this.configurationSave();
        this.onGoalClassesChange();
    };
    ConfigurationService.prototype.onGoalClassesChange = function () {
        this.goalClasses.next(this.configuration.goalClasses);
    };
    ConfigurationService.prototype.getGoalClasses = function () {
        return this.goalClasses.asObservable();
    };
    ConfigurationService.prototype.getIndicatorClassDivisionConfig = function () {
        return this.indicatorClassDivisionConfig.asObservable();
    };
    ConfigurationService.prototype.getIndicatorClasses = function () {
        return this.indicatorClasses.asObservable();
    };
    ConfigurationService.prototype.onIndicatorClassesChange = function () {
        this.indicatorClasses.next(this.configuration.indicatorClasses);
    };
    ConfigurationService.prototype.setGoalClassDivision = function (classDivision) {
        this.configuration.goalClassDivision = classDivision;
        this.configurationSave();
    };
    ConfigurationService.prototype.getGoalClassDivision = function () {
        return this.goalClassDivision.asObservable();
    };
    ConfigurationService.prototype.onGoalClassDivision = function () {
        this.goalClassDivision.next(this.configuration.goalClassDivision);
    };
    ConfigurationService.prototype.getGoalClassBreaks = function () {
        return this.goalClassBreaks.asObservable();
    };
    ConfigurationService.prototype.onGoalClassBreaks = function () {
        this.goalClassBreaks.next(this.configuration.goalClassBreaks);
    };
    ConfigurationService.prototype.getIndicatorById = function (indicatorId) {
        return Indicators.filter(function (item) { return item.id == indicatorId; })[0];
    };
    ConfigurationService.prototype.getInputById = function (inputId) {
        inputId = inputId.split("$").shift();
        return Inputs.filter(function (item) { return item.id == inputId; })[0];
    };
    ConfigurationService.prototype.configurationSave = function () {
        localStorage.setItem("frogisConfiguration", JSON.stringify(this.configuration));
    };
    ConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.ɵɵinject(i1.FrogisService)); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}());
export { ConfigurationService };
