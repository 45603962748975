import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FrogisService} from "./frogis/frogis.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent{
    version: string;

    constructor(
        private frogisService:FrogisService,
    ) {
        this.frogisService.version().then(result => {
            this.version = result.version;
        });
    }
}
