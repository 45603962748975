var SessionHttpInterceptor = /** @class */ (function () {
    function SessionHttpInterceptor() {
        this.sessionId = "";
    }
    SessionHttpInterceptor.prototype.intercept = function (req, next) {
        var update = {};
        if (this.sessionId == "" && localStorage.getItem("sessionId") != null) {
            this.sessionId = localStorage.getItem("sessionId");
        }
        if (this.sessionId != "") {
            update = {
                withCredentials: true,
            };
        }
        req = req.clone(update);
        return next.handle(req);
    };
    return SessionHttpInterceptor;
}());
export { SessionHttpInterceptor };
