import {MatTableDataSource} from "@angular/material/table";
import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {sampleCorrelation} from "simple-statistics";
import {ConfigurationService} from "./configuration.service";
import {Subject} from "rxjs";
import {ProgressDialogComponent} from "../shared/progress-dialog.component";
import {FrogisService} from "./frogis.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: "correlation-matrix",
    templateUrl: './correlation-matrix.component.html',
    styleUrls: ['./correlation-matrix.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class CorrelationMatrixComponent implements OnInit {
    correlationMatrixColumns = [];
    correlationMatrixDataSource: MatTableDataSource<any>;
    correlationMatrixDisplayedColumns;

    private preSelectedIndicators: string[] = [];
    private selectedIndicators: string[] = [];
    private successfullyCalculatedIndicators: string[] = [];

    progressDialog;

    constructor(
        private configurationService: ConfigurationService,
        private dialog: MatDialog,
        private frogisService: FrogisService,
    ) {
    }

    ngOnInit() {
        this.configurationService.getPreselectedIndicators().subscribe(
            {
                next: value => {
                    if (value == null) {
                        return;
                    }

                    this.preSelectedIndicators = value;
                }
            }
        );

        this.configurationService.getSelectedIndicators().subscribe(
            {
                next: selectedIndicators => {
                    if (selectedIndicators == null) {
                        return;
                    }

                    this.selectedIndicators = selectedIndicators;
                }
            }
        );

        this.configurationService.getCalculationResult().subscribe(
            {
                next: value => {
                    this.calculateCorrelation(value);
                }
            }
        );
    }

    private calculateCorrelation(calculationResult: any): void {
        let correlation = [];
        let indicators = Object.keys(calculationResult);

        for (let indicatorId1 of indicators) {
            let correlationRow = {"indicatorId": indicatorId1};

            if (calculationResult[indicatorId1].length == 0) {
                //@2do show error that indicator is missing values
                return;
            }

            for (let indicatorId2 of indicators) {
                if (indicatorId1 != indicatorId2) {
                    correlationRow[indicatorId2] = sampleCorrelation(
                        calculationResult[indicatorId1],
                        calculationResult[indicatorId2]
                    ).toFixed(2);
                } else {
                    correlationRow[indicatorId2] = '-';
                }
            }

            correlation.push(correlationRow);
        }

        let correlationMatrixColumns = [
            { columnDef: "indicatorId",
                header: "",
                classIndicator: (columnIndicatorId: string) => `mat-cell`,
                type: `name`,
                cell: (columnIndicatorId: string, row: any) => `${row["indicatorId"]}`,
            }
        ];

        for (let indicatorId of indicators) {
            correlationMatrixColumns.push(
                {
                    columnDef: indicatorId,
                    header: indicatorId,
                    classIndicator: (columnIndicatorId: string) => `mat-cell show-indicator`,
                    type: `value`,
                    cell: (columnIndicatorId: string, row: any) => `${row[columnIndicatorId]}`,
                }
            );
        }

        this.correlationMatrixColumns = correlationMatrixColumns;
        this.correlationMatrixDisplayedColumns = ["check"].concat(this.correlationMatrixColumns.map(c => c.columnDef));
        this.correlationMatrixDataSource = new MatTableDataSource<any>(correlation);
    }

    getClassByValue(val) {
        if (val == "-") {
            return "bg-none";
        }

        if (Math.abs(val) >= 0.8) {
            return "bg-cor08"
        } else if (Math.abs(val) >= 0.6) {
            return "bg-cor06"
        } else if (Math.abs(val) >= 0.4) {
            return "bg-cor04"
        } else {
            return "bg-none"
        }
    }

    changeSelectedIndicator(indicatorId: string, event: any) {
        if (event.checked) {
            this.configurationService.addSelectedIndicator(indicatorId);
        } else {
            this.configurationService.removeSelectedIndicator(indicatorId);
        }
    }

    isIndicatorSelected(indicatorId: string) {
        return this.selectedIndicators.includes(indicatorId);
    }

    calculateCorrelationMatrix() {
        this.frogisService.calculate(this.preSelectedIndicators);

        const progress = new Subject<number>();

        this.progressDialog = this.dialog.open(ProgressDialogComponent, {
            data: {
                title: `Calculation`,
                content: `Calculating indicators`,
                successContent: `Calculation done`,
                buttonName: `Ok`,
                progress: progress.asObservable(),
                hideOk: true
            },
            height: '250px',
            width: '520px',
        }).componentInstance;

        setTimeout(() => { this.checkCalculationStatus(progress); }, 5000);
    }

    private getCalculationResult(indicators: string[]): void {
        this.frogisService.getCalculationResult(this.preSelectedIndicators).then(
            result => {
                this.configurationService.setCalculationResult(result.values);
            }
        );
    }

    private checkCalculationStatus(progress) {
        this.frogisService.getCalculationProgress().then(
            result => {
                let howMany = 0;
                let done = 0;
                let errorIndicators = [];
                let success = [];

                for (const indicator in result.indicators) {
                    howMany++;
                    const status = result.indicators[indicator];

                    if (status != "IN_PROGRESS") {
                        done++;

                        if (status == "ERROR") {
                            errorIndicators.push(indicator);
                        } else {
                            success.push(indicator);
                        }
                    }
                }

                let numProgress = (done*100/howMany).toFixed(0);

                progress.next(numProgress);

                if (numProgress == "100") {
                    if (errorIndicators.length > 0) {
                        let error = {error: {message: "Problem with calculation of following indicators: " + errorIndicators.join(", ")
                        + ". Please upload another files or uncheck indicators from calculation."}};

                        progress.error(error);
                    } else {
                        progress.complete();
                    }

                    this.getCalculationResult(success);
                } else {
                    setTimeout(() => { this.checkCalculationStatus(progress); }, 5000);
                }
            }
        )
    }
}
