import {Injectable} from "@angular/core";
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {environment} from "../../environments/environment";

const serviceUrl = `${environment.url}/data`;

@Injectable()
export class FrogisService {
    constructor(private http: HttpClient) {}

    public version(): Promise<any> {
        return this.http.get(`${serviceUrl}/version`).toPromise();
    }

    public hello(sessionId: string, forceReset: boolean): Promise<HelloResponse> {
        return this.http.post<HelloResponse>(`${serviceUrl}/hello?forceReset=${forceReset}`, {sessionId: sessionId}).toPromise()
    }

    private globalDataCoverage: Subject<any> = new Subject<any>();

    public getGlobalDataCoverage(): Subject<any> {
        return this.globalDataCoverage;
    }

    public calculate(indicatorIds: string[]) {
        let indicatorIdsJoined = indicatorIds.join(",");

        this.http.post(`${serviceUrl}/calculate/${indicatorIdsJoined}`, {}).toPromise().then(
            (response) => {
                console.log(response);
            }
        );
    }

    public getCalculationProgress(): Promise<CalculationStatus> {
        return this.http.get<CalculationStatus>(`${serviceUrl}/calculation/status`).toPromise();
    }

    public getCalculationResult(indicatorIds: string[]): Promise<any> {
        let indicatorIdsJoined = indicatorIds.join(",");

        return this.http.get(`${serviceUrl}/results/${indicatorIdsJoined}`).toPromise();
    }

    public download(inputIds: string[]) {
        let inputIdsJoined = inputIds.join(",");

        this.http.post(`${serviceUrl}/download/${inputIdsJoined}`, {}).toPromise().then(
            (response) => {
                console.log(response);
            }
        );
    }

    public getDownloadProgress(): Promise<DownloadStatus> {
        return this.http.get<DownloadStatus>(`${serviceUrl}/download/status`).toPromise();
    }

    public upload(file: File, inputId: string): Observable<number> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('inputId', inputId);

        const req = new HttpRequest('POST', `${serviceUrl}/upload`, formData, {
            reportProgress: true
        });

        const progress = new Subject<number>();

        this.http.request(req).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round((100 * event.loaded) / event.total);
                    progress.next(percentDone);
                } else if (event instanceof HttpResponse) {
                    if (event.status == 200) {
                        if (inputId == 'SubBasin' && event.body.hasOwnProperty('globalDataCoverage')) {
                            this.globalDataCoverage.next(event.body['globalDataCoverage']);
                        }

                        progress.complete();
                    }
                }
            },
            error => {
                progress.error(error);
            });

        return progress.asObservable();
    }

    public getGeoJson(): Promise<any> {
        return this.http.get<any>(`${serviceUrl}/spu`).toPromise()
    }
}

export class CalculationStatus {
    constructor(
        public indicators: any,
    ) {
    }
}

export class DownloadStatus {
    constructor(
        public inputs: any,
        public progress: number,
    ) {
    }
}

export class HelloRequest {
    constructor(
        public sessionId: string,
    ) {
    }
}

export class HelloResponse {
    constructor(
        public sessionId: string,
        public reset: boolean,
    ) {
    }
}
