import {Component, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ClassDivision, DivisionMethod} from "./frogis.model";
import {FormArray, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ConfigurationService} from "./configuration.service";
import {CalculationService} from "./calculation.service";
import {MatTableDataSource} from "@angular/material/table";
import {LatLng, Map, tileLayer, geoJSON, GeoJSON, Control, DomUtil, Browser} from "leaflet";
import {FrogisService} from "./frogis.service";
import {DebugComponent} from "./debug.component";

export const monotonicValidator = (formGroup: FormGroup): ValidationErrors | null => {
    const rangeArray: FormArray = (formGroup.get('ranges') as FormArray);

    if (rangeArray.controls.length < 1) {
        return null;
    }

    let lastVal;
    let i = 0;
    let min = rangeArray.controls[0].get('range').value;
    let max = rangeArray.controls[rangeArray.controls.length - 1].get('range').value;

    for (const control of rangeArray.controls) {
        let curVal = control.get('range').value;

        if (curVal.value == "") {
            let o = {empty: true};

            control.get('range').setErrors(o);

            return o;
        }

        if (!lastVal) {
            lastVal = curVal;
        }

        if (i == 0 || i == rangeArray.controls.length - 1) {
            i++;
            continue;
        }

        if (curVal < min || curVal > max) {
            let o = {outOfRange: true};

            control.get('range').setErrors(o);

            return o;
        }

        if (lastVal >= curVal) {
            let o = {notMonotonic: true};
            control.get('range').setErrors(o);
            return o;
        }

        lastVal = curVal;
        i++;
    }

    return null;
};

@Component({
    selector: "goal-summary",
    templateUrl: './goal-summary.component.html',
    styleUrls: ['./goal-summary.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [DebugComponent],
})
export class GoalSummaryComponent implements OnInit{

    goalSummaryDataSource: MatTableDataSource<any>;
    formBuilder = new FormBuilder();

    goalClassRanges = [];
    classDivisionMethods: any = DivisionMethod;
    goalClassDivisionForm: FormGroup;
    goalSummaryForm: FormGroup;

    goalValues: number[] = [];
    goalClasses: number[] = [];
    colorMap: string[] = ['', `#ffffd4`, `#fed98e`, `#fe9929`, `#d95f0e`, `#993404`];

    leafletZoom = 11;
    leafletCenter = new LatLng(52.23, 21.03);

    map: Map;
    showMap: boolean = false;
    spuMap: GeoJSON;
    spuJSON: any;

    defaultLineColor: string = `#666666`;

    mapInfoBox: Control;
    mapLegendBox: Control;

    constructor(
        private calculationService: CalculationService,
        private configurationService: ConfigurationService,
        private frogisService: FrogisService,
        private debugComponent: DebugComponent
    ) {
        this.goalClassDivisionForm = this.formBuilder.group(
            {
                numClasses: ['', Validators.required],
                divisionMethod: ['', Validators.required],
            }
        );

        this.configurationService.getGoalClassDivision().subscribe({
                next: goalClassDivision => {
                    if (!goalClassDivision) {
                        return;
                    }

                    this.goalClassDivisionForm.setValue(goalClassDivision);
                    this.onChangeClassDivision(goalClassDivision.numClasses);

                    this.refreshStatsValues();
                }
            }
        );

        this.configurationService.getGoalClassBreaks().subscribe({
                next: goalClassBreaks => {
                    if (!goalClassBreaks) {
                        return;
                    }

                    this.buildGoalSummaryForm(goalClassBreaks);
                }
            }
        );

        this.configurationService.getGoalValues().subscribe(
            {
                next: goalValues => {
                    this.goalValues = goalValues;

                    this.refreshStatsValues();
                }
            }
        );

        this.configurationService.getGoalClasses().subscribe(
            {
                next: goalClasses => {
                    this.goalClasses = goalClasses;
                    console.log('refresh goal classes');

                    this.refreshMap();
                }
            }
        );
    }

    ngOnInit() {
        this.goalSummaryForm = this.formBuilder.group({
            ranges: this.formBuilder.array([]),
        });
    }

    onMapReady(map: Map): void {
        this.map = map;

        if (!this.showMap || !this.goalClasses) {
            return;
        }
    }

    onLayerMouseOver(component) {
        return function(e) {
            const target = e.target;
            const properties = target.feature.properties;

            const data: any = component.debugComponent.getData();
            const featureData: any = data[properties.ID*1 - 1];

            let text = '';

            for (const key in featureData) {
                text += `<b>${key}</b>: ${featureData[key]}<br>`;
            }

            target.bindPopup(text);
            target.openPopup();

            target.setStyle({
                weight: 2,
            });



            console.log(data[properties.ID]);

            (target._map.owner.mapInfoBox as any).update(properties);

            if (!Browser.ie && !Browser.opera12 && !Browser.edge) {
                target.bringToFront();
            }
        }
    }

    onLayerMouseOut(e) {
        e.target.setStyle({
            weight: 1,
        });

        (e.target._map.owner.mapInfoBox as any).update(null);

        e.target.closePopup();
    }

    refreshMap(force: boolean = false): void {
        if (!this.goalSummaryForm.valid) {
            return;
        }

        if (force) {
            this.showMap = true;
        }

        if (!this.showMap || !this.goalClasses) {
            return;
        }

        setTimeout( () => {
            this.map.invalidateSize();
        }, 100);

        const tiles = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });

        tiles.addTo(this.map);

        let map = this.map;
        (map as any).owner = this;

        this.addLegendToMap(map);

        if (!this.spuJSON) {
            this.frogisService.getGeoJson().then((json: any) => {
                this.spuJSON = json;

                this.updateMap(this.spuJSON);
            }).catch(
                //@2do show error
            );
        } else {
            this.updateMap(this.spuJSON);
        }
    }

    private updateMap(json: any) {
        const component  = this;

        for (let i in json.features) {
            const goalClass = component.goalClasses[i];
            json.features[i].properties['class'] = (goalClass) ? goalClass : 'n/a';
        }

        if (this.spuMap) {
            this.map.removeLayer(this.spuMap);
        }

        this.spuMap = geoJSON(json, {
            onEachFeature: function (feature, layer) {
                layer.bindTooltip(feature.properties['ID'] + '', {permanent: true, direction: 'center'});
                layer.on({
                    mouseover: component.onLayerMouseOver(component),
                    mouseout: component.onLayerMouseOut,
                });
            },
            style: function (feature) {
                if (feature.properties['class'] != 'n/a') {
                    return {
                        color: component.defaultLineColor,
                        fillColor: component.colorMap[feature.properties['class']],
                        weight: 1,
                        opacity: 1,
                        dashArray: '3',
                        fillOpacity: 0.7,
                    };
                }

                return {
                    color: component.defaultLineColor,
                    fillColor: "#ffffff",
                    weight: 1,
                    opacity: 1,
                    dashArray: '3',
                    fillOpacity: 0.0,
                }
            },
        });
        this.spuMap.addTo(this.map);

        this.leafletCenter = this.spuMap.getBounds().getCenter();
    }

    private addLegendToMap(map: Map): void {
        if (this.mapInfoBox) {
            return;
        }

        const component = this;

        this.mapInfoBox = new Control();

        this.mapInfoBox.onAdd = function() {
            this._div = DomUtil.create('div', 'map-box');
            this.update();
            return this._div;
        };

        (this.mapInfoBox as any).update = function(properties: any = null) {
            this._div.innerHTML = `<h5>Valorization goal</h5>` + (properties ?
                `<b>ID:</b> ${properties['ID']}<br><b>class: </b>${properties.class}` : 'Point area on map.');
        };

        this.mapInfoBox.addTo(map);

        this.mapLegendBox = new Control({position: 'bottomright'});

        this.mapLegendBox.onAdd = function() {
            this._div = DomUtil.create('div', 'map-box map-legend');
            this.update(component.goalClassRanges.length);

            return this._div;
        };

        (this.mapLegendBox as any).update = function(numClasses: number) {
            this._div.innerHTML = '<h5>Neads and posibility for water retention</h5>';

            for (let i = 1; i < numClasses; i++) {
                let label: string = i + "";

                if (i == 1) {
                    label += " low potential";
                } else if (i == numClasses-1) {
                    label += " high potential";
                }

                this._div.innerHTML += `<i style="background:${component.colorMap[i]}"></i>${label}<br>`;
            }

            return this._div;
        }

        this.mapLegendBox.addTo(map);
    }

    calculateGoalClassRanges() {
        if (!this.goalClassDivisionForm.valid) {
            return;
        }

        let classDivision: ClassDivision = this.goalClassDivisionForm.getRawValue();

        if (this.goalValues) {
            console.log('has goal values');

            let breaks = this.calculationService.calculateBreaks(
                classDivision.numClasses,
                classDivision.divisionMethod,
                this.goalValues,
            );

            this.onChangeClassDivision(classDivision.numClasses);

            this.buildGoalSummaryForm(breaks);
            this.refreshStatsValues();

            this.configurationService.setGoalClassDivision(
                this.goalClassDivisionForm.getRawValue()
            );

            this.onChangeClassDivision(classDivision.numClasses);

            this.recalculateAndSaveClassDivision(this.goalSummaryForm.getRawValue());
        }

        console.log(classDivision);
    }

    private buildGoalSummaryForm(breaks: number[]): void {
        let inputControls = [];

        for (let i =0; i<breaks.length; i++) {
            let breakValue = breaks[i];

            if (!breakValue) {
                breakValue = 0;
            }

            inputControls.push(
                this.formBuilder.group({
                    range: [parseFloat(breakValue.toFixed(2)), Validators.required],
                })
            );
        }

        this.goalSummaryForm = this.formBuilder.group({
            ranges: this.formBuilder.array(inputControls),
        }, {validator: monotonicValidator});

        this.goalSummaryForm.markAllAsTouched();

        // when any value in this form has changed
        this.goalSummaryForm.valueChanges.subscribe(
            values => {
                if (this.goalSummaryForm.valid) {
                    this.recalculateAndSaveClassDivision(values);
                }
            }
        );
    }

    private onChangeClassDivision(numClasses: number): void {
        let ranges = [];

        for (let i=0; i < numClasses + 1; i++) {
            ranges.push(i);
        }

        if (this.mapLegendBox) {
            (this.mapLegendBox as any).update(numClasses + 1);
        }

        this.goalClassRanges = ranges;
    }

    private recalculateAndSaveClassDivision(formValues: any) {
        const breaks: number[] = [];

        for (const range of formValues.ranges) {
            breaks.push(range.range);
        }

        console.log(breaks);
        console.log(this.goalValues);

        const classes = this.calculationService.changeValuesToClasses(
            this.goalValues,
            breaks,
            true
        );

        console.log(classes);

        this.configurationService.setGoalClasses(classes);
        this.configurationService.setGoalClassBreaks(breaks);

        this.showMap = true;
    }

    private refreshStatsValues() {
        if (this.goalClassDivisionForm.valid && this.goalValues && this.goalValues.length > 0) {
            let stats = this.calculationService.calculateBasicStats(this.goalValues);

            let goalSummary = [{
                mean: stats.mean,
                min: stats.min,
                max: stats.max,
                stDev: stats.stDev,
            }];

            this.goalSummaryDataSource = new MatTableDataSource<any>(goalSummary);
        }
    }

    downloadCsv() {
        const data: any = this.debugComponent.getData();

        if (data.length == 0) {
            alert("Cannot generate geojson file because there are no features.");
            return;
        }

        const header = Object.keys(data[0]);

        const csv = data.map((row) =>
            header.map((name) => row[name]).join(`,`)
        );
        csv.unshift(header.join(`,`));

        this.downloadFile(csv.join(`\r\n`), "report.csv", 'text/csv');
    }

    downloadGeoJSON() {
        const geoJSON:any = Object.assign({}, this.spuMap.toGeoJSON());
        const data: any = this.debugComponent.getData();

        for (const i in geoJSON.features) {
            geoJSON.features[i].properties = data[i];
        }

        this.downloadFile(JSON.stringify(geoJSON), "report.geojson", 'text/plain;charset=utf-8');
    }

    private downloadFile(o: any, fileName: string, fileType: string) {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([o], { type:  fileType}));

        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
