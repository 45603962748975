/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./debug.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/table";
import * as i3 from "@angular/cdk/table";
import * as i4 from "../../../../../../node_modules/@angular/material/table/typings/index.ngfactory";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "@angular/common";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./debug.component";
import * as i13 from "./configuration.service";
var styles_DebugComponent = [i0.styles];
var RenderType_DebugComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DebugComponent, data: {} });
export { RenderType_DebugComponent as RenderType_DebugComponent };
function View_DebugComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "th", [["class", "mat-header-cell"], ["mat-header-cell", ""], ["role", "columnheader"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatHeaderCell, [i3.CdkColumnDef, i1.ElementRef], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.header; _ck(_v, 2, 0, currVal_0); }); }
function View_DebugComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [["class", "mat-cell"], ["mat-cell", ""], ["role", "gridcell"]], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatCell, [i3.CdkColumnDef, i1.ElementRef], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.classIndicator(_v.parent.context.$implicit.columnDef), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.cell(_v.parent.context.$implicit.columnDef, _v.context.$implicit); _ck(_v, 2, 0, currVal_1); }); }
function View_DebugComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), i1.ɵprd(6144, null, "MAT_SORT_HEADER_COLUMN_DEF", null, [i3.CdkColumnDef]), i1.ɵdid(2, 16384, [[1, 4]], 3, i3.CdkColumnDef, [], { name: [0, "name"] }, null), i1.ɵqud(335544320, 5, { cell: 0 }), i1.ɵqud(335544320, 6, { headerCell: 0 }), i1.ɵqud(335544320, 7, { footerCell: 0 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_DebugComponent_3)), i1.ɵdid(7, 16384, [[6, 4]], 0, i3.CdkHeaderCellDef, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_DebugComponent_4)), i1.ɵdid(9, 16384, [[5, 4]], 0, i3.CdkCellDef, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.columnDef; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DebugComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [["class", "mat-header-row"], ["mat-header-row", ""], ["role", "row"]], null, null, null, i4.View_MatHeaderRow_0, i4.RenderType_MatHeaderRow)), i1.ɵprd(6144, null, i3.CdkHeaderRow, null, [i2.MatHeaderRow]), i1.ɵdid(2, 49152, null, 0, i2.MatHeaderRow, [], null, null)], null, null); }
function View_DebugComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [["class", "mat-row"], ["mat-row", ""], ["role", "row"]], null, null, null, i4.View_MatRow_0, i4.RenderType_MatRow)), i1.ɵprd(6144, null, i3.CdkRow, null, [i2.MatRow]), i1.ɵdid(2, 49152, null, 0, i2.MatRow, [], null, null)], null, null); }
function View_DebugComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "table", [["class", "mat-table"], ["id", "indicatorsTable"], ["mat-table", ""]], null, null, null, i4.View_MatTable_0, i4.RenderType_MatTable)), i1.ɵdid(1, 2342912, null, 4, i2.MatTable, [i1.IterableDiffers, i1.ChangeDetectorRef, i1.ElementRef, [8, null], [2, i5.Directionality], i6.DOCUMENT, i7.Platform], { dataSource: [0, "dataSource"] }, null), i1.ɵqud(603979776, 1, { _contentColumnDefs: 1 }), i1.ɵqud(603979776, 2, { _contentRowDefs: 1 }), i1.ɵqud(603979776, 3, { _contentHeaderRowDefs: 1 }), i1.ɵqud(603979776, 4, { _contentFooterRowDefs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DebugComponent_2)), i1.ɵdid(7, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 2, null, View_DebugComponent_5)), i1.ɵdid(9, 540672, null, 0, i2.MatHeaderRowDef, [i1.TemplateRef, i1.IterableDiffers], { columns: [0, "columns"] }, null), i1.ɵprd(2048, [[3, 4]], i3.CdkHeaderRowDef, null, [i2.MatHeaderRowDef]), (_l()(), i1.ɵand(0, null, null, 2, null, View_DebugComponent_6)), i1.ɵdid(12, 540672, null, 0, i2.MatRowDef, [i1.TemplateRef, i1.IterableDiffers], { columns: [0, "columns"] }, null), i1.ɵprd(2048, [[2, 4]], i3.CdkRowDef, null, [i2.MatRowDef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.indicatorsDataSource; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.indicatorsColumns; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.indicatorsDisplayedColumns; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.indicatorsDisplayedColumns; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_DebugComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reset() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i9.MatButton, [i1.ElementRef, i7.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Reset session"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DebugComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.indicatorsDisplayedColumns && (_co.indicatorsDisplayedColumns.length > 0)); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_DebugComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "debug", [], null, null, null, View_DebugComponent_0, RenderType_DebugComponent)), i1.ɵdid(1, 49152, null, 0, i12.DebugComponent, [i13.ConfigurationService], null, null)], null, null); }
var DebugComponentNgFactory = i1.ɵccf("debug", i12.DebugComponent, View_DebugComponent_Host_0, {}, {}, []);
export { DebugComponentNgFactory as DebugComponentNgFactory };
