import { MatTableDataSource } from "@angular/material/table";
import { OnInit } from "@angular/core";
import { sampleCorrelation } from "simple-statistics";
import { ConfigurationService } from "./configuration.service";
import { Subject } from "rxjs";
import { ProgressDialogComponent } from "../shared/progress-dialog.component";
import { FrogisService } from "./frogis.service";
import { MatDialog } from "@angular/material/dialog";
var CorrelationMatrixComponent = /** @class */ (function () {
    function CorrelationMatrixComponent(configurationService, dialog, frogisService) {
        this.configurationService = configurationService;
        this.dialog = dialog;
        this.frogisService = frogisService;
        this.correlationMatrixColumns = [];
        this.preSelectedIndicators = [];
        this.selectedIndicators = [];
        this.successfullyCalculatedIndicators = [];
    }
    CorrelationMatrixComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.configurationService.getPreselectedIndicators().subscribe({
            next: function (value) {
                if (value == null) {
                    return;
                }
                _this.preSelectedIndicators = value;
            }
        });
        this.configurationService.getSelectedIndicators().subscribe({
            next: function (selectedIndicators) {
                if (selectedIndicators == null) {
                    return;
                }
                _this.selectedIndicators = selectedIndicators;
            }
        });
        this.configurationService.getCalculationResult().subscribe({
            next: function (value) {
                _this.calculateCorrelation(value);
            }
        });
    };
    CorrelationMatrixComponent.prototype.calculateCorrelation = function (calculationResult) {
        var correlation = [];
        var indicators = Object.keys(calculationResult);
        for (var _i = 0, indicators_1 = indicators; _i < indicators_1.length; _i++) {
            var indicatorId1 = indicators_1[_i];
            var correlationRow = { "indicatorId": indicatorId1 };
            if (calculationResult[indicatorId1].length == 0) {
                //@2do show error that indicator is missing values
                return;
            }
            for (var _a = 0, indicators_2 = indicators; _a < indicators_2.length; _a++) {
                var indicatorId2 = indicators_2[_a];
                if (indicatorId1 != indicatorId2) {
                    correlationRow[indicatorId2] = sampleCorrelation(calculationResult[indicatorId1], calculationResult[indicatorId2]).toFixed(2);
                }
                else {
                    correlationRow[indicatorId2] = '-';
                }
            }
            correlation.push(correlationRow);
        }
        var correlationMatrixColumns = [
            { columnDef: "indicatorId",
                header: "",
                classIndicator: function (columnIndicatorId) { return "mat-cell"; },
                type: "name",
                cell: function (columnIndicatorId, row) { return "" + row["indicatorId"]; },
            }
        ];
        for (var _b = 0, indicators_3 = indicators; _b < indicators_3.length; _b++) {
            var indicatorId = indicators_3[_b];
            correlationMatrixColumns.push({
                columnDef: indicatorId,
                header: indicatorId,
                classIndicator: function (columnIndicatorId) { return "mat-cell show-indicator"; },
                type: "value",
                cell: function (columnIndicatorId, row) { return "" + row[columnIndicatorId]; },
            });
        }
        this.correlationMatrixColumns = correlationMatrixColumns;
        this.correlationMatrixDisplayedColumns = ["check"].concat(this.correlationMatrixColumns.map(function (c) { return c.columnDef; }));
        this.correlationMatrixDataSource = new MatTableDataSource(correlation);
    };
    CorrelationMatrixComponent.prototype.getClassByValue = function (val) {
        if (val == "-") {
            return "bg-none";
        }
        if (Math.abs(val) >= 0.8) {
            return "bg-cor08";
        }
        else if (Math.abs(val) >= 0.6) {
            return "bg-cor06";
        }
        else if (Math.abs(val) >= 0.4) {
            return "bg-cor04";
        }
        else {
            return "bg-none";
        }
    };
    CorrelationMatrixComponent.prototype.changeSelectedIndicator = function (indicatorId, event) {
        if (event.checked) {
            this.configurationService.addSelectedIndicator(indicatorId);
        }
        else {
            this.configurationService.removeSelectedIndicator(indicatorId);
        }
    };
    CorrelationMatrixComponent.prototype.isIndicatorSelected = function (indicatorId) {
        return this.selectedIndicators.includes(indicatorId);
    };
    CorrelationMatrixComponent.prototype.calculateCorrelationMatrix = function () {
        var _this = this;
        this.frogisService.calculate(this.preSelectedIndicators);
        var progress = new Subject();
        this.progressDialog = this.dialog.open(ProgressDialogComponent, {
            data: {
                title: "Calculation",
                content: "Calculating indicators",
                successContent: "Calculation done",
                buttonName: "Ok",
                progress: progress.asObservable(),
                hideOk: true
            },
            height: '250px',
            width: '520px',
        }).componentInstance;
        setTimeout(function () { _this.checkCalculationStatus(progress); }, 5000);
    };
    CorrelationMatrixComponent.prototype.getCalculationResult = function (indicators) {
        var _this = this;
        this.frogisService.getCalculationResult(this.preSelectedIndicators).then(function (result) {
            _this.configurationService.setCalculationResult(result.values);
        });
    };
    CorrelationMatrixComponent.prototype.checkCalculationStatus = function (progress) {
        var _this = this;
        this.frogisService.getCalculationProgress().then(function (result) {
            var howMany = 0;
            var done = 0;
            var errorIndicators = [];
            var success = [];
            for (var indicator in result.indicators) {
                howMany++;
                var status_1 = result.indicators[indicator];
                if (status_1 != "IN_PROGRESS") {
                    done++;
                    if (status_1 == "ERROR") {
                        errorIndicators.push(indicator);
                    }
                    else {
                        success.push(indicator);
                    }
                }
            }
            var numProgress = (done * 100 / howMany).toFixed(0);
            progress.next(numProgress);
            if (numProgress == "100") {
                if (errorIndicators.length > 0) {
                    var error = { error: { message: "Problem with calculation of following indicators: " + errorIndicators.join(", ")
                                + ". Please upload another files or uncheck indicators from calculation." } };
                    progress.error(error);
                }
                else {
                    progress.complete();
                }
                _this.getCalculationResult(success);
            }
            else {
                setTimeout(function () { _this.checkCalculationStatus(progress); }, 5000);
            }
        });
    };
    return CorrelationMatrixComponent;
}());
export { CorrelationMatrixComponent };
