import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClassDivision, DivisionMethod, IndicatorSummary} from './frogis.model';
import {MatTableDataSource} from '@angular/material/table';
import {CalculationService} from './calculation.service';
import {ConfigurationService} from './configuration.service';

@Component({
    selector: 'indicators-summary',
    templateUrl: './indicators-summary.component.html',
    styleUrls: ['./indicators-summary.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class IndicatorsSummaryComponent implements OnInit {
    indicatorsClassDivisionSummaryForm: FormGroup;
    indicatorsSummaryForm: FormGroup;

    formBuilder: FormBuilder = new FormBuilder();

    indicatorsSummaryDataSource: MatTableDataSource<IndicatorSummary>;
    indicatorsSummary: IndicatorSummary[];

    indicatorClassDivision = [];

    classDivisionMethods: any = DivisionMethod;

    selectedIndicators: string[];
    calculationResult: any;

    indicatorClassBreaks: number[];
    indicatorWeight: number[];
    indicatorStimulationing: number[];

    constructor(
        private calculationService: CalculationService,
        private configurationService: ConfigurationService,
    ) {
        this.configurationService.getCalculationResult().subscribe(
            {
                next: calculationResult => {
                    this.calculationResult = calculationResult;
                }
            }
        );

        this.configurationService.getIndicatorClassDivisionConfig().subscribe(
            {
                next: config => {
                    if (!config.indicatorsClassDivision) {
                        return;
                    }

                    this.indicatorsClassDivisionSummaryForm.setValue(config.indicatorsClassDivision);
                    this.onChangeClassDivision(config.indicatorsClassDivision.numClasses);

                    this.indicatorsSummary = [];

                    if (this.calculationResult) {
                        for (const indicatorId of this.selectedIndicators) {
                            if (!config.indicatorStimulationing[indicatorId]) {
                                continue;
                            }

                            const values = this.calculationResult[indicatorId];
                            const indicator = this.configurationService.getIndicatorById(indicatorId);
                            const stats = this.calculationService.calculateBasicStats(values);

                            const indicatorSummary: IndicatorSummary = {
                                indicatorId: indicatorId,
                                mean: stats.mean,
                                min: stats.min,
                                max: stats.max,
                                stDev: stats.stDev,
                                unit: indicator.unit,
                                defaultStimulating: config.indicatorStimulationing[indicatorId],
                                currentStimulating: config.indicatorStimulationing[indicatorId],
                                breaks: config.indicatorClassBreaks[indicatorId],
                                weight: config.indicatorWeight[indicatorId],
                            };

                            this.indicatorsSummary.push(indicatorSummary);
                        }
                    }

                    this.buildIndicatorsSummaryForm(this.indicatorsSummary);
                    this.indicatorsSummaryDataSource = new MatTableDataSource<IndicatorSummary>(this.indicatorsSummary);

                    this.indicatorsSummaryForm.valueChanges.subscribe(
                values => {
                        this.indicatorsSummaryForm.markAllAsTouched();
                    }
                    );
                }
            }
        );
    }

    ngOnInit() {
        this.indicatorsClassDivisionSummaryForm = this.formBuilder.group(
            {
                numClasses: ['', Validators.required],
                divisionMethod: ['', Validators.required],
            }
        );

        this.configurationService.getSelectedIndicators().subscribe(
            {
                next: selectedIndicators => {
                    this.selectedIndicators = selectedIndicators;
                }
            }
        );

        this.indicatorsSummaryForm = this.formBuilder.group({
            indicators: this.formBuilder.array([]),
        });
    }

    onStimulationChange(element, formGroupIndex) {
        let currentRanges = [];
        const rangeFormArray: FormArray = ((this.indicatorsSummaryForm.get('indicators') as FormArray).at(formGroupIndex).get('ranges')) as FormArray;

        for (let i = 0; i < rangeFormArray.length; i++) {
            currentRanges.push(rangeFormArray.at(i).get('range').value);
        }

        currentRanges = currentRanges.reverse();

        for (let i = 0; i < rangeFormArray.length; i++) {
            rangeFormArray.at(i).get('range').setValue(currentRanges[i]);
        }

        for (const ind in this.indicatorsSummary) {
            if (this.indicatorsSummary[ind].indicatorId === element.indicatorId) {
                this.indicatorsSummary[ind].breaks = currentRanges;
                break;
            }
        }

        this.configurationService.setIndicatorClasses(
            element.indicatorId,
            this.indicatorsClassDivisionSummaryForm.getRawValue()
        );
    }

    calculateIndicatorsClassRanges() {
        if (!this.indicatorsClassDivisionSummaryForm.valid) {
            return;
        }

        const classDivision: ClassDivision = this.indicatorsClassDivisionSummaryForm.getRawValue();
        this.indicatorsSummary = [];

        if (this.calculationResult) {
            for (const indicatorId of this.selectedIndicators) {
                const values = this.calculationResult[indicatorId];

                if (values === undefined) {
                    console.warn('Missing indicator values: ' + indicatorId);

                    continue;
                }

                const indicator = this.configurationService.getIndicatorById(indicatorId);
                const stats = this.calculationService.calculateBasicStats(values);
                let breaks = this.calculationService.calculateBreaks(
                    classDivision.numClasses,
                    classDivision.divisionMethod,
                    this.calculationResult[indicatorId],
                );

                if (indicator.stimulating === 'negative') {
                    breaks = breaks.reverse();
                }

                const indicatorSummary: IndicatorSummary = {
                    indicatorId: indicatorId,
                    mean: stats.mean,
                    min: stats.min,
                    max: stats.max,
                    stDev: stats.stDev,
                    unit: indicator.unit,
                    defaultStimulating: indicator.stimulating,
                    currentStimulating: indicator.stimulating,
                    breaks: breaks,
                    weight: 1,
                };

                this.indicatorsSummary.push(indicatorSummary);
            }
        }

        this.buildIndicatorsSummaryForm(this.indicatorsSummary);
        this.indicatorsSummaryDataSource = new MatTableDataSource<IndicatorSummary>(this.indicatorsSummary);

        this.configurationService.setIndicatorsClassDivisionConfiguration(
            this.indicatorsClassDivisionSummaryForm.getRawValue()
        );

        this.onChangeClassDivision(classDivision.numClasses);
        this.recalculateAndSaveClassDivision(this.indicatorsSummaryForm.getRawValue());
    }

    private recalculateAndSaveClassDivision(formValues: any) {
        const allClasses = {};
        const allWeights = {};

        for (const indicator of formValues.indicators) {
            let breaks = [];

            for (let i = 0; i < indicator.ranges.length; i++) {
                breaks.push(indicator.ranges[i].range);
            }

            const classes = this.calculationService.changeValuesToClasses(
                this.calculationResult[indicator.indicatorId],
                breaks,
                indicator.stimulationing === 'positive',
            );

            this.configurationService.setIndicatorClasses(
                indicator.indicatorId,
                classes,
            );

            this.configurationService.setClassDivisors(
                indicator.indicatorId,
                breaks,
                indicator.weight,
                indicator.stimulationing,
            );

            allClasses[indicator.indicatorId] = classes;
            allWeights[indicator.indicatorId] = indicator.weight;
        }

        const goal = this.calculationService.calculateGoalValues(allClasses, allWeights);
        this.configurationService.setGoalValues(goal);
    }

    public refreshIndicatorsSummary(selectedIndicators: string[], calculationResult: any): void {
        this.selectedIndicators = selectedIndicators;
        this.calculationResult = calculationResult;
    }

    private buildIndicatorsSummaryForm(indicatorsSummary: IndicatorSummary[]): void {
        const indicatorsFormControls: any[] = [];

        for (const indicatorSummary of indicatorsSummary) {
            indicatorsFormControls.push(
                this.createIndicatorSummaryFormGroup(indicatorSummary)
            );
        }

        this.indicatorsSummaryForm = this.formBuilder.group(
            {
                indicators: this.formBuilder.array(indicatorsFormControls)
            }
        );
    }

    private createIndicatorSummaryFormGroup(indicatorSummary: IndicatorSummary): FormGroup {
        const inputControls = [];

        for (let i = 0; i < indicatorSummary.breaks.length; i++) {
            const breakValue = indicatorSummary.breaks[i];

            inputControls.push(
                this.formBuilder.group({
                    range: [parseFloat(breakValue.toFixed(2)), Validators.required],
                })
            );
        }

        return this.formBuilder.group({
            indicatorId: [indicatorSummary.indicatorId],
            stimulationing: [indicatorSummary.currentStimulating],
            weight: [indicatorSummary.weight, Validators.required],
            ranges: this.formBuilder.array(inputControls),
        });
    }

    private onChangeClassDivision(numClasses: number): void {
        const ranges = [];

        for (let i = 0; i < numClasses + 1; i++) {
            ranges.push(i);
        }

        this.indicatorClassDivision = ranges;
    }

    public save() {
        this.recalculateAndSaveClassDivision(this.indicatorsSummaryForm.getRawValue());
    }
}
