import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from "@angular/common/http";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";
var serviceUrl = environment.url + "/data";
var FrogisService = /** @class */ (function () {
    function FrogisService(http) {
        this.http = http;
        this.globalDataCoverage = new Subject();
    }
    FrogisService.prototype.version = function () {
        return this.http.get(serviceUrl + "/version").toPromise();
    };
    FrogisService.prototype.hello = function (sessionId, forceReset) {
        return this.http.post(serviceUrl + "/hello?forceReset=" + forceReset, { sessionId: sessionId }).toPromise();
    };
    FrogisService.prototype.getGlobalDataCoverage = function () {
        return this.globalDataCoverage;
    };
    FrogisService.prototype.calculate = function (indicatorIds) {
        var indicatorIdsJoined = indicatorIds.join(",");
        this.http.post(serviceUrl + "/calculate/" + indicatorIdsJoined, {}).toPromise().then(function (response) {
            console.log(response);
        });
    };
    FrogisService.prototype.getCalculationProgress = function () {
        return this.http.get(serviceUrl + "/calculation/status").toPromise();
    };
    FrogisService.prototype.getCalculationResult = function (indicatorIds) {
        var indicatorIdsJoined = indicatorIds.join(",");
        return this.http.get(serviceUrl + "/results/" + indicatorIdsJoined).toPromise();
    };
    FrogisService.prototype.download = function (inputIds) {
        var inputIdsJoined = inputIds.join(",");
        this.http.post(serviceUrl + "/download/" + inputIdsJoined, {}).toPromise().then(function (response) {
            console.log(response);
        });
    };
    FrogisService.prototype.getDownloadProgress = function () {
        return this.http.get(serviceUrl + "/download/status").toPromise();
    };
    FrogisService.prototype.upload = function (file, inputId) {
        var _this = this;
        var formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('inputId', inputId);
        var req = new HttpRequest('POST', serviceUrl + "/upload", formData, {
            reportProgress: true
        });
        var progress = new Subject();
        this.http.request(req).subscribe(function (event) {
            if (event.type === HttpEventType.UploadProgress) {
                var percentDone = Math.round((100 * event.loaded) / event.total);
                progress.next(percentDone);
            }
            else if (event instanceof HttpResponse) {
                if (event.status == 200) {
                    if (inputId == 'SubBasin' && event.body.hasOwnProperty('globalDataCoverage')) {
                        _this.globalDataCoverage.next(event.body['globalDataCoverage']);
                    }
                    progress.complete();
                }
            }
        }, function (error) {
            progress.error(error);
        });
        return progress.asObservable();
    };
    FrogisService.prototype.getGeoJson = function () {
        return this.http.get(serviceUrl + "/spu").toPromise();
    };
    return FrogisService;
}());
export { FrogisService };
var CalculationStatus = /** @class */ (function () {
    function CalculationStatus(indicators) {
        this.indicators = indicators;
    }
    return CalculationStatus;
}());
export { CalculationStatus };
var DownloadStatus = /** @class */ (function () {
    function DownloadStatus(inputs, progress) {
        this.inputs = inputs;
        this.progress = progress;
    }
    return DownloadStatus;
}());
export { DownloadStatus };
var HelloRequest = /** @class */ (function () {
    function HelloRequest(sessionId) {
        this.sessionId = sessionId;
    }
    return HelloRequest;
}());
export { HelloRequest };
var HelloResponse = /** @class */ (function () {
    function HelloResponse(sessionId, reset) {
        this.sessionId = sessionId;
        this.reset = reset;
    }
    return HelloResponse;
}());
export { HelloResponse };
