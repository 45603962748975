import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DivisionMethod } from './frogis.model';
import { MatTableDataSource } from '@angular/material/table';
import { CalculationService } from './calculation.service';
import { ConfigurationService } from './configuration.service';
var IndicatorsSummaryComponent = /** @class */ (function () {
    function IndicatorsSummaryComponent(calculationService, configurationService) {
        var _this = this;
        this.calculationService = calculationService;
        this.configurationService = configurationService;
        this.formBuilder = new FormBuilder();
        this.indicatorClassDivision = [];
        this.classDivisionMethods = DivisionMethod;
        this.configurationService.getCalculationResult().subscribe({
            next: function (calculationResult) {
                _this.calculationResult = calculationResult;
            }
        });
        this.configurationService.getIndicatorClassDivisionConfig().subscribe({
            next: function (config) {
                if (!config.indicatorsClassDivision) {
                    return;
                }
                _this.indicatorsClassDivisionSummaryForm.setValue(config.indicatorsClassDivision);
                _this.onChangeClassDivision(config.indicatorsClassDivision.numClasses);
                _this.indicatorsSummary = [];
                if (_this.calculationResult) {
                    for (var _i = 0, _a = _this.selectedIndicators; _i < _a.length; _i++) {
                        var indicatorId = _a[_i];
                        if (!config.indicatorStimulationing[indicatorId]) {
                            continue;
                        }
                        var values = _this.calculationResult[indicatorId];
                        var indicator = _this.configurationService.getIndicatorById(indicatorId);
                        var stats = _this.calculationService.calculateBasicStats(values);
                        var indicatorSummary = {
                            indicatorId: indicatorId,
                            mean: stats.mean,
                            min: stats.min,
                            max: stats.max,
                            stDev: stats.stDev,
                            unit: indicator.unit,
                            defaultStimulating: config.indicatorStimulationing[indicatorId],
                            currentStimulating: config.indicatorStimulationing[indicatorId],
                            breaks: config.indicatorClassBreaks[indicatorId],
                            weight: config.indicatorWeight[indicatorId],
                        };
                        _this.indicatorsSummary.push(indicatorSummary);
                    }
                }
                _this.buildIndicatorsSummaryForm(_this.indicatorsSummary);
                _this.indicatorsSummaryDataSource = new MatTableDataSource(_this.indicatorsSummary);
                _this.indicatorsSummaryForm.valueChanges.subscribe(function (values) {
                    _this.indicatorsSummaryForm.markAllAsTouched();
                });
            }
        });
    }
    IndicatorsSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.indicatorsClassDivisionSummaryForm = this.formBuilder.group({
            numClasses: ['', Validators.required],
            divisionMethod: ['', Validators.required],
        });
        this.configurationService.getSelectedIndicators().subscribe({
            next: function (selectedIndicators) {
                _this.selectedIndicators = selectedIndicators;
            }
        });
        this.indicatorsSummaryForm = this.formBuilder.group({
            indicators: this.formBuilder.array([]),
        });
    };
    IndicatorsSummaryComponent.prototype.onStimulationChange = function (element, formGroupIndex) {
        var currentRanges = [];
        var rangeFormArray = (this.indicatorsSummaryForm.get('indicators').at(formGroupIndex).get('ranges'));
        for (var i = 0; i < rangeFormArray.length; i++) {
            currentRanges.push(rangeFormArray.at(i).get('range').value);
        }
        currentRanges = currentRanges.reverse();
        for (var i = 0; i < rangeFormArray.length; i++) {
            rangeFormArray.at(i).get('range').setValue(currentRanges[i]);
        }
        for (var ind in this.indicatorsSummary) {
            if (this.indicatorsSummary[ind].indicatorId === element.indicatorId) {
                this.indicatorsSummary[ind].breaks = currentRanges;
                break;
            }
        }
        this.configurationService.setIndicatorClasses(element.indicatorId, this.indicatorsClassDivisionSummaryForm.getRawValue());
    };
    IndicatorsSummaryComponent.prototype.calculateIndicatorsClassRanges = function () {
        if (!this.indicatorsClassDivisionSummaryForm.valid) {
            return;
        }
        var classDivision = this.indicatorsClassDivisionSummaryForm.getRawValue();
        this.indicatorsSummary = [];
        if (this.calculationResult) {
            for (var _i = 0, _a = this.selectedIndicators; _i < _a.length; _i++) {
                var indicatorId = _a[_i];
                var values = this.calculationResult[indicatorId];
                if (values === undefined) {
                    console.warn('Missing indicator values: ' + indicatorId);
                    continue;
                }
                var indicator = this.configurationService.getIndicatorById(indicatorId);
                var stats = this.calculationService.calculateBasicStats(values);
                var breaks = this.calculationService.calculateBreaks(classDivision.numClasses, classDivision.divisionMethod, this.calculationResult[indicatorId]);
                if (indicator.stimulating === 'negative') {
                    breaks = breaks.reverse();
                }
                var indicatorSummary = {
                    indicatorId: indicatorId,
                    mean: stats.mean,
                    min: stats.min,
                    max: stats.max,
                    stDev: stats.stDev,
                    unit: indicator.unit,
                    defaultStimulating: indicator.stimulating,
                    currentStimulating: indicator.stimulating,
                    breaks: breaks,
                    weight: 1,
                };
                this.indicatorsSummary.push(indicatorSummary);
            }
        }
        this.buildIndicatorsSummaryForm(this.indicatorsSummary);
        this.indicatorsSummaryDataSource = new MatTableDataSource(this.indicatorsSummary);
        this.configurationService.setIndicatorsClassDivisionConfiguration(this.indicatorsClassDivisionSummaryForm.getRawValue());
        this.onChangeClassDivision(classDivision.numClasses);
        this.recalculateAndSaveClassDivision(this.indicatorsSummaryForm.getRawValue());
    };
    IndicatorsSummaryComponent.prototype.recalculateAndSaveClassDivision = function (formValues) {
        var allClasses = {};
        var allWeights = {};
        for (var _i = 0, _a = formValues.indicators; _i < _a.length; _i++) {
            var indicator = _a[_i];
            var breaks = [];
            for (var i = 0; i < indicator.ranges.length; i++) {
                breaks.push(indicator.ranges[i].range);
            }
            var classes = this.calculationService.changeValuesToClasses(this.calculationResult[indicator.indicatorId], breaks, indicator.stimulationing === 'positive');
            this.configurationService.setIndicatorClasses(indicator.indicatorId, classes);
            this.configurationService.setClassDivisors(indicator.indicatorId, breaks, indicator.weight, indicator.stimulationing);
            allClasses[indicator.indicatorId] = classes;
            allWeights[indicator.indicatorId] = indicator.weight;
        }
        var goal = this.calculationService.calculateGoalValues(allClasses, allWeights);
        this.configurationService.setGoalValues(goal);
    };
    IndicatorsSummaryComponent.prototype.refreshIndicatorsSummary = function (selectedIndicators, calculationResult) {
        this.selectedIndicators = selectedIndicators;
        this.calculationResult = calculationResult;
    };
    IndicatorsSummaryComponent.prototype.buildIndicatorsSummaryForm = function (indicatorsSummary) {
        var indicatorsFormControls = [];
        for (var _i = 0, indicatorsSummary_1 = indicatorsSummary; _i < indicatorsSummary_1.length; _i++) {
            var indicatorSummary = indicatorsSummary_1[_i];
            indicatorsFormControls.push(this.createIndicatorSummaryFormGroup(indicatorSummary));
        }
        this.indicatorsSummaryForm = this.formBuilder.group({
            indicators: this.formBuilder.array(indicatorsFormControls)
        });
    };
    IndicatorsSummaryComponent.prototype.createIndicatorSummaryFormGroup = function (indicatorSummary) {
        var inputControls = [];
        for (var i = 0; i < indicatorSummary.breaks.length; i++) {
            var breakValue = indicatorSummary.breaks[i];
            inputControls.push(this.formBuilder.group({
                range: [parseFloat(breakValue.toFixed(2)), Validators.required],
            }));
        }
        return this.formBuilder.group({
            indicatorId: [indicatorSummary.indicatorId],
            stimulationing: [indicatorSummary.currentStimulating],
            weight: [indicatorSummary.weight, Validators.required],
            ranges: this.formBuilder.array(inputControls),
        });
    };
    IndicatorsSummaryComponent.prototype.onChangeClassDivision = function (numClasses) {
        var ranges = [];
        for (var i = 0; i < numClasses + 1; i++) {
            ranges.push(i);
        }
        this.indicatorClassDivision = ranges;
    };
    IndicatorsSummaryComponent.prototype.save = function () {
        this.recalculateAndSaveClassDivision(this.indicatorsSummaryForm.getRawValue());
    };
    return IndicatorsSummaryComponent;
}());
export { IndicatorsSummaryComponent };
