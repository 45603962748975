import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from "@angular/cdk/table";
import {
  MatButtonModule,
  MatInputModule,
  MatTableModule,
  MatIconModule,
  MatSnackBarModule,
  MatFormFieldModule,
  MatSortModule,
  MatSelectModule,
  MatTabsModule,
} from "@angular/material";
import { SharedModule } from "../shared/shared.module";
import {FrogisService} from "./frogis.service";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {RouterModule} from "@angular/router";
import {FrogisComponent} from "./frogis.component";
import {MarkdownModule} from "ngx-markdown";
import {MatChipsModule} from "@angular/material/chips";
import {MatDialogModule} from "@angular/material/dialog";
import {CalculationService} from "./calculation.service";
import {IndicatorsSummaryComponent} from "./indicators-summary.component";
import {CorrelationMatrixComponent} from "./correlation-matrix.component";
import {ConfigurationService} from "./configuration.service";
import {GoalSummaryComponent} from "./goal-summary.component";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {DebugComponent} from "./debug.component";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    imports: [
        CdkTableModule,
        CommonModule,
        FormsModule,
        LeafletModule.forRoot(),
        MarkdownModule.forChild(),
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule
    ],
    declarations: [
        CorrelationMatrixComponent,
        DebugComponent,
        FrogisComponent,
        GoalSummaryComponent,
        IndicatorsSummaryComponent,
    ],
    entryComponents: [
        FrogisComponent
    ],
    providers: [
        CalculationService,
        ConfigurationService,
        FrogisService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FrogisModule {}
