import { MatDialogRef } from '@angular/material';
var DialogComponent = /** @class */ (function () {
    function DialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    DialogComponent.prototype.okClick = function () {
        this.dialogRef.close();
    };
    return DialogComponent;
}());
export { DialogComponent };
