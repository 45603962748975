import { Component, Inject, ViewEncapsulation } from "@angular/core";

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  title: string;
  content: string;
}

@Component({
  selector: 'dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: [ 'dialog.component.css' ],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent {
  constructor(
      public dialogRef: MatDialogRef<DialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  okClick(): void {
      this.dialogRef.close();
  }
}
