import { max, mean, min, quantile, standardDeviation } from 'simple-statistics';
import { DivisionMethod } from './frogis.model';
var CalculationService = /** @class */ (function () {
    function CalculationService() {
    }
    CalculationService.prototype.calculateBasicStats = function (values) {
        return {
            mean: mean(values).toFixed(2),
            min: (Math.floor(min(values) * 100) / 100).toFixed(2),
            max: (Math.ceil(max(values) * 100) / 100).toFixed(2),
            stDev: standardDeviation(values).toFixed(2),
        };
    };
    CalculationService.prototype.calculateBreaks = function (classNum, divisionMethod, values) {
        switch (divisionMethod) {
            case DivisionMethod.EQUAL:
                return this.calculateEqualBreaks(values, classNum);
            case DivisionMethod.JENKS:
                return this.calculateJenksBreaks(values, classNum);
            case DivisionMethod.QUANTILE:
                return this.calculateQuantileBreaks(values, classNum);
        }
    };
    CalculationService.prototype.calculateEqualBreaks = function (values, classNum) {
        var stats = this.calculateBasicStats(values);
        var range = stats.max - stats.min;
        var step = range / classNum;
        var lastVal = stats.min * 1;
        var ranges = [lastVal];
        for (var i = 0; i < classNum; i++) {
            lastVal += step;
            ranges.push(lastVal);
        }
        return ranges;
    };
    CalculationService.prototype.calculateQuantileBreaks = function (values, classNum) {
        var step = 1 / classNum;
        var lastVal = 0;
        var ranges = [];
        for (var i = 0; i < classNum + 1; i++) {
            ranges.push(quantile(values, lastVal));
            lastVal += step;
        }
        return ranges;
    };
    /**
     * Jenks calculation based on https://observablehq.com/@jdev42092/jenks-breaks-using-simple-statistics
     */
    CalculationService.prototype.calculateJenksBreaks = function (data, classNum) {
        data = data.slice().sort(function (a, b) { return a - b; });
        var lower_class_limits = this.jenksMatrices(data, classNum), k = data.length - 1, kClass = [], countNum = classNum;
        kClass[classNum] = data[data.length - 1];
        kClass[0] = data[0];
        while (countNum > 1) {
            kClass[countNum - 1] = data[lower_class_limits[k][countNum] - 2];
            k = lower_class_limits[k][countNum] - 1;
            countNum--;
        }
        return kClass;
    };
    CalculationService.prototype.jenksMatrices = function (data, classesNum) {
        var lowerClassLimits = [], varianceCombinations = [], i, j, variance = 0;
        for (i = 0; i < data.length + 1; i++) {
            var tmp1 = [], tmp2 = [];
            for (j = 0; j < classesNum + 1; j++) {
                tmp1.push(0);
                tmp2.push(0);
            }
            lowerClassLimits.push(tmp1);
            varianceCombinations.push(tmp2);
        }
        for (i = 1; i < classesNum + 1; i++) {
            lowerClassLimits[1][i] = 1;
            varianceCombinations[1][i] = 0;
            for (j = 2; j < data.length + 1; j++) {
                varianceCombinations[j][i] = Infinity;
            }
        }
        for (var l = 2; l < data.length + 1; l++) {
            var sum = 0, sum_squares = 0, w = 0, i4 = 0;
            for (var m = 1; m < l + 1; m++) {
                var lower_class_limit = l - m + 1, val = data[lower_class_limit - 1];
                w++;
                sum += val;
                sum_squares += val * val;
                variance = sum_squares - (sum * sum) / w;
                i4 = lower_class_limit - 1;
                if (i4 !== 0) {
                    for (j = 2; j < classesNum + 1; j++) {
                        if (varianceCombinations[l][j] >=
                            (variance + varianceCombinations[i4][j - 1])) {
                            lowerClassLimits[l][j] = lower_class_limit;
                            varianceCombinations[l][j] = variance +
                                varianceCombinations[i4][j - 1];
                        }
                    }
                }
            }
            lowerClassLimits[l][1] = 1;
            varianceCombinations[l][1] = variance;
        }
        return lowerClassLimits;
    };
    CalculationService.prototype.changeValuesToClasses = function (values, classBreaks, isIncreasing) {
        var classedValues = [];
        var lastClass = classBreaks.length - 1;
        var _classBreaks = Object.assign([], classBreaks);
        if (!isIncreasing) {
            _classBreaks = _classBreaks.reverse();
        }
        for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
            var value = values_1[_i];
            for (var classBreakNum = 1; classBreakNum <= lastClass; classBreakNum++) {
                var classBreakValue = _classBreaks[classBreakNum];
                if (value < classBreakValue) {
                    if (!isIncreasing) {
                        classedValues.push(lastClass - classBreakNum + 1);
                    }
                    else {
                        classedValues.push(classBreakNum);
                    }
                    break;
                }
                else if (classBreakNum === lastClass) {
                    if (!isIncreasing) {
                        classedValues.push(lastClass - lastClass + 1);
                    }
                    else {
                        classedValues.push(lastClass);
                    }
                }
            }
        }
        return classedValues;
    };
    CalculationService.prototype.calculateGoalValues = function (indicatorClasses, indicatorWeights) {
        var indicatorIds = Object.keys(indicatorClasses);
        var spusNumber = indicatorClasses[indicatorIds[0]].length;
        var goalValues = [];
        for (var i = 0; i < spusNumber; i++) {
            var value = 0;
            for (var _i = 0, indicatorIds_1 = indicatorIds; _i < indicatorIds_1.length; _i++) {
                var indicatorId = indicatorIds_1[_i];
                value += indicatorClasses[indicatorId][i] * indicatorWeights[indicatorId];
            }
            goalValues.push(value);
        }
        return goalValues;
    };
    return CalculationService;
}());
export { CalculationService };
