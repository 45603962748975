<input type="file" #shapeFile style="display: none" (change)="onFilesAdded('shapefile')" accept=".zip"/>
<input type="file" #rasterFile style="display: none" (change)="onFilesAdded('raster')" accept=".tif,.tiff"/>

<mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabChanged($event)" [class.hide-debug]="showDebug === false">
    <mat-tab label="Spatial planning units">
        <form [formGroup]="spatialPlanningUnitsForm" class="full-size">
            <p>Please select type of spatial planning units and upload a shapefile with them.</p>
            <mat-form-field>
                <mat-label>SPU</mat-label>
                <mat-select formControlName="spuType">
                    <mat-option *ngFor="let spuType of spuTypes | keyvalue" [value]="spuType.key"> {{spuType.value}}</mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            <div class="mat-form-field ng-tns-c5-1 mat-primary mat-form-field-type-mat-select mat-form-field-appearance-legacy mat-form-field-can-float ng-pristine mat-form-field-should-float ng-valid ng-touched">
                <div class="mat-form-field-wrapper">
                    <div class="mat-form-field-flex">
                        <div class="mat-form-field-infix" (keydown)="onKeyDown($event)" tabindex="0">
                            <button mat-button mat-stroked-button (click)="addFiles('SubBasin')" color="primary">Choose a file</button>
                            &nbsp;
                            <mat-chip-list style="display:inline-block;">
                                <mat-chip color="primary" *ngIf="!fileUploadStatus['SubBasin']">Needs SPU files</mat-chip>
                                <mat-chip style="background: #81ad5b" *ngIf="fileUploadStatus['SubBasin'] && fileUploadStatus['SubBasin'].status == 'success'">File uploaded</mat-chip>
                            </mat-chip-list>
                            <span class="mat-form-field-label-wrapper">
                                <label class="mat-form-field-label ng-tns-c5-3 mat-empty mat-form-field-empty ng-star-inserted">
                                    <mat-label>Shapefile with SPU</mat-label>
                                </label>
                            </span>
                        </div>
                    </div>
                    <div>
                        <span style="font-size:10px;font-weight:300;margin-top:20px;">Maximum catchment area (sum of SPUs) is 5km<sup>2</sup>, and maximum file size is 50MiB.</span>
                    </div>
                </div>
            </div>
        </form>
    </mat-tab>
    <mat-tab label="Goals and indicators" [disabled]="!spatialPlanningUnitsForm.valid || !hasSpuFile">
        <form [formGroup]="goalAndIndicatorsForm">
            <p>Please select a goal of calculations. After selecting a goal recommended indicators will be highlighted. </p>
            <mat-form-field>
                <mat-label>Goal</mat-label>
                <mat-select formControlName="goal">
                    <mat-option *ngFor="let goalType of goalTypes | keyvalue" [value]="goalType.key"> {{goalType.value}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-label class="fake-form-field" style="display: block">Legend</mat-label>
            <div>
                <div class="necessity-legend">
                    <span class="necessity-label label-primary">Recommended</span> usually good quality data available and easy to calculate<br>
                    <span class="necessity-label necessity-n">Necessary</span> default stimulation direction of indicator shows need for water retention<br>
                    <span class="necessity-label necessity-p">Possible</span> default stimulation direction of indicator shows possibility for water retention<br>
                </div>
            </div>
            <table mat-table id="indicators" [dataSource]="indicatorsDataSource" multiTemplateDataRows>
                <ng-container matColumnDef="check">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox (change)="changePreselectedIndicator(element.id, $event)" [checked]="isIndicatorPreselected(element.id)"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Indicator name </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="indicator-name">{{element.id}}
                            <span class="necessity-label necessity-{{element.necessity | lowercase}}">{{element.necessity}}</span>
                            <span *ngIf="isIndicatorRecommendedForCurrentGoal(element.id)" class="necessity-label label-primary">Recommended</span>
                        </div>
                        <div>{{element.name}}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="topic">
                    <th mat-header-cell *matHeaderCellDef> Topics </th>
                    <td mat-cell *matCellDef="let element"> {{element.topic}} </td>
                </ng-container>
                <ng-container matColumnDef="required">
                    <th mat-header-cell *matHeaderCellDef> Required input data </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngFor="let input of element.input">
                            <a href="#{{input}}" >{{configurationService.getInputById(input).name}}</a>;
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element;">
                        <mat-chip-list>
                            <mat-chip color="mat-primary" *ngIf="!hasFoundInGlobalData(element.input)">Needs input files</mat-chip>
                            <mat-chip class="green-chip" *ngIf="hasFoundInGlobalData(element.input)">Global data available</mat-chip>
                        </mat-chip-list>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Description</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-stroked-button (click)="expandedIndicator = expandedIndicator === element ? null : element">
                            <span *ngIf="expandedIndicator !== element">show description</span>
                            <span *ngIf="expandedIndicator === element">hide description</span>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <td mat-cell [attr.colspan]="6" *matCellDef="let element" [@detailExpand]="element == expandedIndicator ? 'expanded' : 'collapsed'">
                        <markdown class="md-description" [src]="'./assets/docs/indicators/' + element.id + '.md'" katex></markdown>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['check', 'name', 'topic', 'required', 'status', 'action']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['check', 'name', 'topic', 'required', 'status', 'action'];" [class.hidden]="!showIndicator[row.id]"></tr>
                <tr mat-row *matRowDef="let row; columns: ['description'];" class="indicator-description-row" [class.hidden]="!showIndicator[row.id]"></tr>
            </table>
        </form>
    </mat-tab>
    <mat-tab label="Data input" [disabled]="!showInputsPanel">
        <table mat-table id="inputs" [dataSource]="inputsDataSource">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Input data </th>
                <td mat-cell *matCellDef="let element">
                    <div class="indicator-name">{{element.id}}</div>
                    <div>{{element.name}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="fileDescription">
                <th mat-header-cell *matHeaderCellDef> File type </th>
                <td mat-cell *matCellDef="let element"> {{element.file}} <span *ngIf="element.format">({{element.format}})</span> </td>
            </ng-container>
            <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef> Data file </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button mat-stroked-button (click)="addFiles(element.id)">Upload a file</button>&nbsp;
                    <button *ngIf="globalDataCoverage[element.id]" mat-button mat-stroked-button (click)="downloadLayerFromGlobalData([element.id])">Prepare from global data</button>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    <mat-chip-list>
                        <mat-chip *ngIf="!fileUploadStatus[element.id] && !globalDataCoverage[element.id]">Upload a file</mat-chip>
                        <mat-chip *ngIf="!fileUploadStatus[element.id] && globalDataCoverage[element.id]" class="green-chip" matTooltip="Layer with this data found in our database. You may use our data automatically or upload your file.">Upload your file or get from global data</mat-chip>
                        <mat-chip style="background: #81ad5b" *ngIf="fileUploadStatus[element.id] && fileUploadStatus[element.id].status == 'success'">Ready</mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['id', 'fileDescription', 'status', 'file']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['id', 'fileDescription', 'status', 'file'];"></tr>
        </table>
    </mat-tab>
    <mat-tab label="Indicators correlation matrix" [disabled]="!haveAllInputDataToProcess">
        <correlation-matrix #correlationMatrix></correlation-matrix>
    </mat-tab>
    <mat-tab label="Conversion and final aggregation method" [disabled]="!haveSelectedIndicators">
        <indicators-summary #indicatorsSummary></indicators-summary>
    </mat-tab>
    <mat-tab label="Goal valorization method" [disabled]="!haveGoalValues">
        <goal-summary #goalSummary></goal-summary>
    </mat-tab>
    <mat-tab label="Indicator values">
        <p>Below you can find values of all processed indicators. At the beginning the table is empty, after uploading data this table will be filled with values per spu.</p>
        <debug></debug>
    </mat-tab>
</mat-tab-group>
