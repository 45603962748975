/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./yesno.dialog.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./yesno.dialog.component";
import * as i8 from "@angular/material/dialog";
var styles_YesNoDialogComponent = [i0.styles];
var RenderType_YesNoDialogComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_YesNoDialogComponent, data: {} });
export { RenderType_YesNoDialogComponent as RenderType_YesNoDialogComponent };
export function View_YesNoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["mat-dialog-title", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["mat-dialog-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["mat-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["color", "accent"], ["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.yesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Yes"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["color", "accent"], ["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.noClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["No"]))], function (_ck, _v) { var currVal_4 = "accent"; _ck(_v, 7, 0, currVal_4); var currVal_7 = "accent"; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.content; _ck(_v, 4, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_5 = (i1.ɵnov(_v, 11).disabled || null); var currVal_6 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_5, currVal_6); }); }
export function View_YesNoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "yes-no-dialog", [], null, null, null, View_YesNoDialogComponent_0, RenderType_YesNoDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.YesNoDialogComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], null, null); }
var YesNoDialogComponentNgFactory = i1.ɵccf("yes-no-dialog", i7.YesNoDialogComponent, View_YesNoDialogComponent_Host_0, {}, {}, []);
export { YesNoDialogComponentNgFactory as YesNoDialogComponentNgFactory };
