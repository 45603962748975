import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogComponent } from "./dialog.component";
import { HeaderComponent } from "./header.component";
import { YesNoDialogComponent } from "./yesno.dialog.component";
import { MatButtonModule, MatMenuModule } from "@angular/material";
import { ProgressDialogComponent } from "./progress-dialog.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { AppRoutingModule } from "../app-routing.module";

@NgModule({
    declarations: [
        DialogComponent,
        HeaderComponent,
        ProgressDialogComponent,
        YesNoDialogComponent,
  ],
  imports: [ AppRoutingModule, CommonModule, MatButtonModule, MatMenuModule, MatProgressBarModule ],
  exports: [ HeaderComponent, DialogComponent, ProgressDialogComponent, YesNoDialogComponent ],
  entryComponents: [ DialogComponent, YesNoDialogComponent, ProgressDialogComponent ],
  bootstrap: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
