import {Injectable} from '@angular/core';
import {max, mean, min, quantile, standardDeviation} from 'simple-statistics';
import {DivisionMethod} from './frogis.model';

@Injectable()
export class CalculationService {

    public calculateBasicStats(values: number[]): any {
        return {
            mean: mean(values).toFixed(2),
            min: (Math.floor(min(values) * 100) / 100).toFixed(2),
            max: (Math.ceil(max(values) * 100) / 100).toFixed(2),
            stDev: standardDeviation(values).toFixed(2),
        };
    }

    public calculateBreaks(classNum: number, divisionMethod: DivisionMethod, values: number[]): number[] {
        switch (divisionMethod) {
            case DivisionMethod.EQUAL:
                return this.calculateEqualBreaks(values, classNum);
            case DivisionMethod.JENKS:
                return this.calculateJenksBreaks(values, classNum);
            case DivisionMethod.QUANTILE:
                return this.calculateQuantileBreaks(values, classNum);
        }
    }

    public calculateEqualBreaks(values: number[], classNum: number): number[] {
        const stats = this.calculateBasicStats(values);

        const range: number = stats.max - stats.min;
        const step: number = range / classNum;

        let lastVal: number = stats.min * 1;
        const ranges: number[] = [lastVal];

        for (let i = 0; i < classNum; i++) {
            lastVal += step;
            ranges.push(lastVal);
        }

        return ranges;
    }

    public calculateQuantileBreaks(values: number[], classNum: number): number[] {
        const step = 1 / classNum;
        let lastVal = 0;
        const ranges = [];

        for (let i = 0; i < classNum + 1; i++) {
            ranges.push(quantile(values, lastVal));
            lastVal += step;
        }

        return ranges;
    }

    /**
     * Jenks calculation based on https://observablehq.com/@jdev42092/jenks-breaks-using-simple-statistics
     */
    public calculateJenksBreaks(data: number[], classNum: number): number[] {

        data = data.slice().sort(function (a, b) { return a - b; });

        let lower_class_limits = this.jenksMatrices(data, classNum),
            k = data.length - 1,
            kClass = [],
            countNum = classNum;

        kClass[classNum] = data[data.length - 1];
        kClass[0] = data[0];

        while (countNum > 1) {
            kClass[countNum - 1] = data[lower_class_limits[k][countNum] - 2];
            k = lower_class_limits[k][countNum] - 1;
            countNum--;
        }

        return kClass;
    }

    private jenksMatrices(data, classesNum): number[] {

        let lowerClassLimits = [],
            varianceCombinations = [],
            i, j,
            variance = 0;

        for (i = 0; i < data.length + 1; i++) {
            const tmp1 = [], tmp2 = [];
            for (j = 0; j < classesNum + 1; j++) {
                tmp1.push(0);
                tmp2.push(0);
            }
            lowerClassLimits.push(tmp1);
            varianceCombinations.push(tmp2);
        }

        for (i = 1; i < classesNum + 1; i++) {
            lowerClassLimits[1][i] = 1;
            varianceCombinations[1][i] = 0;

            for (j = 2; j < data.length + 1; j++) {
                varianceCombinations[j][i] = Infinity;
            }
        }

        for (let l = 2; l < data.length + 1; l++) {
            let sum = 0,
                sum_squares = 0,
                w = 0,
                i4 = 0;

            for (let m = 1; m < l + 1; m++) {

                const lower_class_limit = l - m + 1,
                    val = data[lower_class_limit - 1];

                w++;

                sum += val;
                sum_squares += val * val;

                variance = sum_squares - (sum * sum) / w;

                i4 = lower_class_limit - 1;

                if (i4 !== 0) {
                    for (j = 2; j < classesNum + 1; j++) {
                        if (varianceCombinations[l][j] >=
                            (variance + varianceCombinations[i4][j - 1])) {
                            lowerClassLimits[l][j] = lower_class_limit;
                            varianceCombinations[l][j] = variance +
                                varianceCombinations[i4][j - 1];
                        }
                    }
                }
            }

            lowerClassLimits[l][1] = 1;
            varianceCombinations[l][1] = variance;
        }

        return lowerClassLimits;
    }

    public changeValuesToClasses(values: number[], classBreaks: number[], isIncreasing: boolean): number[] {
        const classedValues: number[] = [];
        const lastClass = classBreaks.length - 1;
        let _classBreaks = Object.assign([], classBreaks);

        if (!isIncreasing) {
            _classBreaks = _classBreaks.reverse();
        }

        for (const value of values) {
            for (let classBreakNum = 1; classBreakNum <= lastClass; classBreakNum++) {
                const classBreakValue = _classBreaks[classBreakNum];

                if (value < classBreakValue) {
                    if (!isIncreasing) {
                        classedValues.push(lastClass - classBreakNum + 1);
                    } else {
                        classedValues.push(classBreakNum);
                    }
                    break;
                } else if (classBreakNum === lastClass) {
                    if (!isIncreasing) {
                        classedValues.push(lastClass - lastClass + 1);
                    } else {
                        classedValues.push(lastClass);
                    }
                }
            }
        }

        return classedValues;
    }

    public calculateGoalValues(indicatorClasses: any, indicatorWeights: any): number[] {
        const indicatorIds = Object.keys(indicatorClasses);
        const spusNumber = indicatorClasses[indicatorIds[0]].length;
        const goalValues: number[] = [];

        for (let i = 0; i < spusNumber; i++) {
            let value = 0;

            for (const indicatorId of indicatorIds) {
                value += indicatorClasses[indicatorId][i] * indicatorWeights[indicatorId];
            }

            goalValues.push(value);
        }

        return goalValues;
    }
}
