import {Injectable} from "@angular/core";
import {
    ClassDivision,
    GoalType,
    Indicator,
    Indicators,
    Input,
    Inputs,
    SimulationConfiguration,
    SpuType,
} from "./frogis.model";
import {Observable, Subject} from "rxjs";
import {FrogisService} from "./frogis.service";

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    configuration: SimulationConfiguration;

    private hasAllRequiredInputData: Subject<boolean> = new Subject<boolean>();
    private preselectedIndicators: Subject<string[]> = new Subject<string[]>();
    private selectedSpuType: Subject<SpuType> = new Subject<SpuType>();
    private globalDataCoverage: Subject<any> = new Subject<any>();
    private selectedGoal: Subject<GoalType> = new Subject<GoalType>();
    private selectedInputs: Subject<Input[]> = new Subject<Input[]>();
    private selectedIndicators: Subject<string[]> = new Subject<string[]>();
    private calculationResult: Subject<any> = new Subject<any>();
    private indicatorClasses: Subject<any> = new Subject<any>();
    private fileUploadStatus: Subject<any> = new Subject<any>();
    private goalClassDivision: Subject<ClassDivision> = new Subject<ClassDivision>();
    private goalClassBreaks: Subject<number[]> = new Subject<number[]>();
    private goalValues: Subject<any> = new Subject<any>();
    private goalClasses: Subject<any> = new Subject<any>();
    private indicatorClassDivisionConfig: Subject<any> = new Subject<any>();

    constructor(
        private frogisService: FrogisService,
    ) {

        console.log('configuration ini');

        let config = localStorage.getItem("frogisConfiguration");

        if (config) {
            this.configuration = JSON.parse(config);
            localStorage.setItem("sessionId", this.configuration.sessionId);
        } else {
            this.configuration = new SimulationConfiguration();
        }

        console.log(this.configuration);

        this.hello(false);

        this.frogisService.getGlobalDataCoverage().subscribe({
            next: values => {
                this.setGlobalDataCoverage(values);
            }
        });
    }

    public reset(): void {
        this.hello(true);
    }

    private hello(reset: boolean) {
        this.frogisService.hello(localStorage.getItem("sessionId"), reset).then(
            response => {
                if (response.reset) {
                    this.configuration = new SimulationConfiguration();
                }

                localStorage.setItem("sessionId", response.sessionId);

                this.configuration.sessionId = response.sessionId;
                this.configurationSave();

                this.onSpuTypeChange();
                this.onGlobalDataCoverage();
                this.onGoalChange();
                this.onChangePreselectedIndicator();
                this.onFileUploadStatus();
                this.onCalculationResult();
                this.onSelectedIndicatorChange();
                this.onIndicatorsClassDivisionChange();
                this.onIndicatorClassesChange();
                this.onGoalValuesChange();
                this.onGoalClassesChange();
                this.onGoalClassDivision();
                this.onGoalClassBreaks();
            }
        );
    }

    public setSpuType(spuType: SpuType) {
        this.configuration.selectedSpuType = spuType;

        this.configurationSave();
    }

    public getSpuType(): Observable<SpuType> {
        return this.selectedSpuType;
    }

    public onSpuTypeChange(): void {
        this.selectedSpuType.next(this.configuration.selectedSpuType);
    }

    public setGlobalDataCoverage(globalDataCoverage) {
        this.configuration.globalDataCoverage = globalDataCoverage;

        this.configurationSave();

        this.onGlobalDataCoverage();
    }

    public getGlobalDataCoverage(): Observable<any> {
        return this.globalDataCoverage.asObservable();
    }

    public onGlobalDataCoverage(): void {
        this.globalDataCoverage.next(this.configuration.globalDataCoverage);
    }

    public setGoal(goal: GoalType) {
        this.configuration.selectedGoal = goal;

        this.configurationSave();
    }

    public getGoal(): Observable<GoalType> {
        return this.selectedGoal.asObservable();
    }

    public onGoalChange(): void {
        this.selectedGoal.next(this.configuration.selectedGoal);
    }

    public addPreselectedIndicator(indicatorId: string) {
        this.configuration.preSelectedIndicators.push(indicatorId);

        this.onChangePreselectedIndicator();
    }

    public removePreselectedIndicator(indicatorId: string) {
        this.configuration.preSelectedIndicators = this.configuration.preSelectedIndicators.filter(item => item !== indicatorId);

        this.onChangePreselectedIndicator();
    }

    private onChangePreselectedIndicator() {
        let inputIds = new Set();
        let inputs: Input[] = [];

        for (let indicatorId of this.configuration.preSelectedIndicators) {
            for (let inputId of this.getIndicatorById(indicatorId).input) {
                if (inputId.indexOf("$") === -1) {
                    inputIds.add(inputId);
                }
            }
        }

        for (let inputId of Array.from(inputIds)) {
            inputs.push(this.getInputById(inputId));
        }

        this.configuration.selectedInputs = inputs;
        this.configurationSave();

        this.preselectedIndicators.next(this.configuration.preSelectedIndicators);
        this.selectedInputs.next(inputs);

        this.onFileUploadStatus();
    }

    public getPreselectedIndicators(): Observable<string[]> {
        return this.preselectedIndicators.asObservable();
    }

    public getSelectedInputs(): Observable<Input[]> {
        return this.selectedInputs.asObservable();
    }

    public addSelectedIndicator(indicatorId: string) {
        this.configuration.selectedIndicators.push(indicatorId);

        this.configurationSave();

        this.onSelectedIndicatorChange();
    }

    public removeSelectedIndicator(indicatorId: string): void {
        this.configuration.selectedIndicators = this.configuration.selectedIndicators.filter(item => item !== indicatorId);
        this.configurationSave();

        this.onSelectedIndicatorChange();
    }

    public getSelectedIndicators(): Observable<string[]> {
        return this.selectedIndicators.asObservable();
    }

    private onSelectedIndicatorChange(): void {
        this.selectedIndicators.next(this.configuration.selectedIndicators);
    }

    public setFileUploadStatus(inputId: string, status: any): void {
        this.configuration.fileUploadStatus[inputId] = status;
        this.configurationSave();

        this.onFileUploadStatus();
    }

    public getFileUploadStatus(): Observable<any> {
        return this.fileUploadStatus.asObservable();
    }

    public getHasAllRequiredInputData(): Observable<boolean> {
        return this.hasAllRequiredInputData.asObservable();
    }

    public onFileUploadStatus(): void {
        this.fileUploadStatus.next(this.configuration.fileUploadStatus);

        if (this.configuration.preSelectedIndicators.length == 0) {
            this.hasAllRequiredInputData.next(false);
            return;
        }

        let toCheck = this.configuration.selectedInputs;

        if (toCheck.filter(item => item.id == "SubBasin").length == 0) {
            toCheck.push(this.getInputById("SubBasin"))
        }

        //has only spu
        if (toCheck.length == 1) {
            this.hasAllRequiredInputData.next(false);
            return;
        }

        for (let input of toCheck) {
            if (!this.configuration.fileUploadStatus.hasOwnProperty(input.id) || this.configuration.fileUploadStatus[input.id].status != "success") {
                this.hasAllRequiredInputData.next(false);
                return;
            }
        }

        this.hasAllRequiredInputData.next(true);
    }

    public setCalculationResult(result:any) {
        this.configuration.calculationResult = result;
        this.configurationSave();

        this.onCalculationResult();
    }

    public getCalculationResult(): Observable<any> {
        return this.calculationResult.asObservable();
    }

    public onCalculationResult(): void {
        this.calculationResult.next(this.configuration.calculationResult);
    }

    public setIndicatorsClassDivisionConfiguration(classDivision: ClassDivision) {
        this.configuration.indicatorsClassDivision = classDivision;
        this.configurationSave();
    }

    public setIndicatorClasses(indicatorId: string, classes: number[]): void {
        this.configuration.indicatorClasses[indicatorId] = classes;
        this.configurationSave();

        this.onIndicatorClassesChange();
    }

    public setClassDivisors(indicatorId: string, breaks: number[], weight: number, stimulationing: string): void {
        this.configuration.indicatorClassBreaks[indicatorId] = breaks;
        this.configuration.indicatorWeight[indicatorId] = weight;
        this.configuration.indicatorStimulationing[indicatorId] = stimulationing;

        this.configurationSave();
    }

    public setGoalClassBreaks(breaks: number[]): void {
        this.configuration.goalClassBreaks = breaks;

        this.configurationSave();
    }

    public onIndicatorsClassDivisionChange(): void {
        this.indicatorClassDivisionConfig.next({
            indicatorsClassDivision: this.configuration.indicatorsClassDivision,
            indicatorClassBreaks: this.configuration.indicatorClassBreaks,
            indicatorWeight: this.configuration.indicatorWeight,
            indicatorStimulationing: this.configuration.indicatorStimulationing,
        });
    }

    public setGoalValues(values: number[]): void {
        this.configuration.goalValues = values;
        this.configurationSave();

        this.onGoalValuesChange();
    }

    public onGoalValuesChange(): void {
        this.goalValues.next(
            this.configuration.goalValues
        );
    }

    public getGoalValues(): Observable<any> {
        return this.goalValues.asObservable();
    }

    public setGoalClasses(values: number[]): void {
        this.configuration.goalClasses = values;
        this.configurationSave();

        this.onGoalClassesChange();
    }

    public onGoalClassesChange(): void {
        this.goalClasses.next(
            this.configuration.goalClasses
        );
    }

    public getGoalClasses(): Observable<any> {
        return this.goalClasses.asObservable();
    }

    public getIndicatorClassDivisionConfig(): Observable<any> {
        return this.indicatorClassDivisionConfig.asObservable();
    }

    public getIndicatorClasses(): Observable<any> {
        return this.indicatorClasses.asObservable();
    }

    public onIndicatorClassesChange(): void {
        this.indicatorClasses.next(this.configuration.indicatorClasses);
    }

    public setGoalClassDivision(classDivision: ClassDivision) {
        this.configuration.goalClassDivision = classDivision;
        this.configurationSave();
    }

    public getGoalClassDivision(): Observable<ClassDivision> {
        return this.goalClassDivision.asObservable();
    }

    public onGoalClassDivision(): void {
        this.goalClassDivision.next(this.configuration.goalClassDivision);
    }

    public getGoalClassBreaks(): Observable<number[]> {
        return this.goalClassBreaks.asObservable();
    }

    public onGoalClassBreaks(): void {
        this.goalClassBreaks.next(this.configuration.goalClassBreaks);
    }

    public getIndicatorById(indicatorId: string): Indicator {
        return Indicators.filter(item => item.id == indicatorId)[0];
    }

    public getInputById(inputId: string): Input {
        inputId = inputId.split("$").shift();

        return Inputs.filter(item => item.id == inputId)[0];
    }

    private configurationSave(): void {
        localStorage.setItem("frogisConfiguration", JSON.stringify(this.configuration));
    }
}
